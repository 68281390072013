import { AxiosResponse } from "axios";
import {
  CancelServiceRequestDto,
  CreateServiceRequestDto,
  RequestTransferDto,
  ServiceRequestsResponseDto,
} from "types/api/service_requests";

import axiosInstance from "./axios_instance";

const ServicesRequestAPI = {
  getRequestTypes: async (): Promise<ServiceRequestsResponseDto[]> => {
    const res = await axiosInstance.get("/service_request_types");
    return res.data;
  },
  getShipmentRequestType: async (shipment_id: number) => {
    const res = await axiosInstance.get(
      `/shipments/${shipment_id}/service_requests`,
    );
    return res.data;
  },
  createServiceRequest: ({ itemId, ...data }: CreateServiceRequestDto) =>
    axiosInstance.post(`/shipments/${itemId}/service_requests`, data),
  cancelServiceRequest: ({ itemId, requestId }: CancelServiceRequestDto) =>
    axiosInstance.put(
      `/shipments/${itemId}/service_requests/${requestId}/cancel_service_request`,
    ),
  cancelRequestInventoryServiceRequest: ({ taskId }: { taskId: number }) =>
    axiosInstance.post(`/tasks/${taskId}/cancel`),
  createRequestInventoryShipment: ({ id }: { id: number }) =>
    axiosInstance.post(`/items/${id}/create_unpack_task`),
  createRequestTransferShipment: ({
    id,
    partnerTransferId,
    sku,
  }: {
    id: number;
    partnerTransferId: string;
    sku?: string;
  }): Promise<AxiosResponse<RequestTransferDto>> => {
    if (sku) {
      return axiosInstance.post(`/items/request_transfer`, {
        sku: [sku],
        sbid: partnerTransferId,
      });
    }

    return axiosInstance.post(`/shipments/${id}/request_transfer`, {
      ffid: partnerTransferId,
    });
  },
  completeTransferShipment: ({
    id,
    partnerTransferId,
  }: {
    id: number;
    partnerTransferId: string;
  }) => {
    return axiosInstance.post(`/shipments/complete_transfer`, {
      codes: [partnerTransferId],
    });
  },
};

export default ServicesRequestAPI;
