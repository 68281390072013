import React, { FC, useCallback, useEffect } from "react";

import {
  Content,
  ContentWrapper,
  Heading,
} from "@/containers/RightPanel/RightPanel.styles";
import { Icon } from "@/components/Icon";

import { useKBHelpers } from "@/hooks/react-query/dashboard";

import { HelpPanelData, RightPanelType } from "@/store/useRightPanelStore";
import { useAuthStore, useDashboardStore, useRightPanelStore } from "@/store";
import { IconType } from "@/enums";
import { FaqKBHelpersDto } from "@/types/api/dashboard";

import { Answer, Spinner, Title } from "./HelpPanel.styles";

interface HelpPanelProps {
  panelData: HelpPanelData;
}

const HelpPanel: FC<HelpPanelProps> = React.memo(({ panelData }) => {
  const {
    userAuth: { locale: currentLocale },
  } = useAuthStore();
  const { openRightPanel } = useRightPanelStore();
  const { kbHelpersData } = useDashboardStore();
  const { isError: isErrorKBHelpers, isLoading: isLoadingKBHelpers } =
    useKBHelpers(currentLocale);

  const readOnly = panelData.readOnly;
  const hideBackButton = panelData.hideBackButton;
  const title = panelData.headerTitle ?? "FAQ";
  const showHelpersCode = panelData.showHelpersCode ?? "packopt";

  const filteredKbHelpersList = kbHelpersData.filter(
    (faqItem: FaqKBHelpersDto) => faqItem.code === showHelpersCode,
  );

  const openPackOptions = useCallback(
    () =>
      // @ts-ignore
      openRightPanel(RightPanelType.PACK_OPTIONS, {
        ...panelData,
        returnScreen: RightPanelType.STORAGE_ITEM,
      }),
    [panelData, openRightPanel, readOnly],
  );

  return (
    <ContentWrapper>
      <Heading $isBordered>
        {!hideBackButton && (
          <button onClick={openPackOptions}>
            <Icon type={IconType.Arrow} />
          </button>
        )}
        <Title>{title}</Title>
      </Heading>
      <Spinner
        isActive={
          isLoadingKBHelpers && !kbHelpersData.length && isErrorKBHelpers
        }
      />
      {!!filteredKbHelpersList.length && (
        <Content>
          {filteredKbHelpersList.map((faqItem: FaqKBHelpersDto) => (
            <Answer
              key={faqItem.code}
              dangerouslySetInnerHTML={{ __html: faqItem.content ?? "" }}
            />
          ))}
        </Content>
      )}
    </ContentWrapper>
  );
});

export default HelpPanel;
