import React, { useEffect } from "react";

import { useToastStore } from "@/store";

import { BugButtonMessage, BugButtonWrapper } from "./ReportBugButton.styles";

interface ToastReportBugButton {
  message: string;
}

const ToastReportBugButton = ({ message }: ToastReportBugButton) => {
  const { updateIsToastWithAxiosError } = useToastStore();

  useEffect(() => {
    updateIsToastWithAxiosError(true);

    return () => updateIsToastWithAxiosError(false);
  }, []);

  return (
    <BugButtonWrapper>
      <BugButtonMessage>{message}</BugButtonMessage>
    </BugButtonWrapper>
  );
};

export default ToastReportBugButton;
