import React, { FC } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { Icon } from "@/components/Icon";
import { Tooltip } from "@/components/Tooltip";
import { getAddonsCost } from "@/components/TotalCostHint/helpers";

import { useOutgoingRemoveItemFromConsolidation } from "@/hooks/react-query/outgoing";
import {
  useAddonsMethods,
  useConsolidationAddonsMethods,
} from "@/hooks/react-query/services";

import { DetailedShipment } from "@/store/useStorageStore";
import {
  useCartStore,
  useRightPanelStore,
  useServicesStore,
  useStorageStore,
} from "@/store";
import { IconType } from "@/enums";
import { CartQueryKey as QueryKey, StorageQueryKey } from "@/types";

import { getRequestInfo } from "../ServiceRequests/helpers";
import {
  ArrowIcon,
  HelpIcon,
  ParcelButton,
  RemoveButton,
  ReturnButton,
  ReturnSection,
  ReturnTooltip,
  ServicesSection,
  Status,
} from "./ServiceButtons.styles";

interface ServicesButtonsProps {
  shipment: DetailedShipment;
  openPackOptions: () => void;
  openServicePanel: (panel: string, isInventoryItemVariant?: boolean) => void;
  openInventory?: () => void;
  isCartVariant: boolean;
  hasInventoryItems?: boolean;
  originalItem?: any;
  isInventoryItemVariant?: boolean;
  isItemInCart?: boolean;
}

const ServicesButtons: FC<ServicesButtonsProps> = React.memo(
  ({
    openServicePanel,
    openPackOptions,
    openInventory,
    shipment,
    isCartVariant,
    hasInventoryItems,
    isInventoryItemVariant,
    isItemInCart,
    originalItem,
  }) => {
    const { t } = useTranslation("common");
    const { getSelectAddons, consolidationAddonsMethods } = useServicesStore();
    const { closeRightPanel } = useRightPanelStore();
    const { removeCartParcels, cart } = useCartStore();
    const { removeConsolidationFromShipment } = useStorageStore();
    const queryClient = useQueryClient();

    const addonsMethods = getSelectAddons();

    useAddonsMethods({ enabled: !addonsMethods.length });
    useConsolidationAddonsMethods({
      enabled: !consolidationAddonsMethods.length,
    });

    const { mutateAsync: removeItemFromConsolidation } =
      useOutgoingRemoveItemFromConsolidation();

    const addonsCost = getAddonsCost(
      [{ addons: shipment.addons ?? [] }],
      addonsMethods,
    );

    const requests = shipment.service_requests;
    const photoRequest = getRequestInfo(requests, "additionalPhotos");
    const checkPackageRequest = getRequestInfo(requests, "powerCheck");
    const customRequest = getRequestInfo(requests, "serviceByInstruction");
    const description = shipment.description;
    const id = shipment.id;
    const consolidationId = shipment?.consolidation?.id;
    const hasInventory =
      hasInventoryItems || (shipment.items && shipment.items?.length > 1);
    const consolidationState = shipment?.consolidation?.state;
    const isRemovableFromConsolidation =
      consolidationState === "pending" || consolidationState === "requesting";
    const requestInventoryShipmentStatus = shipment.unpacking_task?.state;
    const showTransferRequest =
      Object.keys(shipment).length > 0 && !consolidationState;

    // For shipments in "client_transferring" state show value from a different key. e.g. transferCompleteCode
    const isClientTransferringItem =
      originalItem.state === "client_transferring";
    const requestTransferButtonTitle = isClientTransferringItem
      ? t("serviceRequests.requestTransferShipment.transferCompleteCode")
      : t("serviceRequests.requestTransferShipment.title");

    const handleRemoveAllFromConsolidation = async () => {
      const consolidationItems = shipment.consolidation.items ?? [];
      const itemsToRemove = consolidationItems.filter(
        (item: any) => item.shipment_id === id,
      );

      const ajaxRequests = [];
      for (const item of itemsToRemove) {
        const itemId = item.id;
        ajaxRequests.push(
          removeItemFromConsolidation({
            id: itemId,
            consolidationId,
            isCartVariant,
          }).then(() => {
            removeCartParcels(itemId);
          }),
        );
      }

      await Promise.all(ajaxRequests);
      if (isCartVariant) {
        removeConsolidationFromShipment(id);
        queryClient.invalidateQueries({
          queryKey: [StorageQueryKey.Storage],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKey.Cart],
        });
      }
      closeRightPanel();
    };

    const handleRemoveFromConsolidation = () => {
      const itemId = originalItem.id;

      removeItemFromConsolidation({
        consolidationId: cart?.id ?? 0,
        id: itemId,
        isCartVariant,
      }).then(() => {
        removeCartParcels(itemId);
        closeRightPanel();
      });
    };

    if (isClientTransferringItem) {
      return (
        <ServicesSection>
          <ParcelButton
            onClick={() => openServicePanel("requestTransferShipment")}
          >
            <div>
              <Icon type={IconType.PackOptions} />
              {requestTransferButtonTitle}
            </div>
            <ArrowIcon type={IconType.Arrow} />
          </ParcelButton>
        </ServicesSection>
      );
    }

    if (isInventoryItemVariant) {
      return (
        <div>
          <ServicesSection>
            <ParcelButton onClick={openPackOptions}>
              <div>
                <Icon type={IconType.PackOptions} />
                {t("serviceRequests.packOptions.title")}
              </div>
              {!!addonsCost && <Status>$ {addonsCost}</Status>}
              <ArrowIcon type={IconType.Arrow} />
            </ParcelButton>
          </ServicesSection>
          <ServicesSection>
            <ParcelButton
              onClick={() => openServicePanel("requestTransferShipment", true)}
            >
              <div>
                <Icon type={IconType.PackOptions} />
                {requestTransferButtonTitle}
              </div>
              <ArrowIcon type={IconType.Arrow} />
            </ParcelButton>
          </ServicesSection>
          {isItemInCart && (
            <ServicesSection>
              <ParcelButton onClick={handleRemoveFromConsolidation}>
                <RemoveButton>
                  <Icon type={IconType.Close} />
                  {t("serviceRequests.removeFromConsolidation.actionText")}
                </RemoveButton>
              </ParcelButton>
            </ServicesSection>
          )}
        </div>
      );
    }

    return (
      <div>
        <ServicesSection>
          <ParcelButton onClick={() => openServicePanel("additionalPhotos")}>
            <div>
              <Icon type={IconType.AdditionalPhotos} />
              {t("serviceRequests.additionalPhotos.title")}
            </div>
            {!!photoRequest.hasRequest && (
              <Status>
                {t(`serviceRequests.states.${photoRequest.state}`)}
              </Status>
            )}
            <ArrowIcon type={IconType.Arrow} />
          </ParcelButton>
          <ParcelButton onClick={() => openServicePanel("powerCheck")}>
            <div>
              <Icon type={IconType.PowerCheck} />
              {t("serviceRequests.powerCheck.title")}
            </div>
            {!!checkPackageRequest.hasRequest && (
              <Status>
                {t(`serviceRequests.states.${checkPackageRequest.state}`)}
              </Status>
            )}
            <ArrowIcon type={IconType.Arrow} />
          </ParcelButton>
          <ParcelButton
            onClick={() => openServicePanel("serviceByInstruction")}
          >
            <div>
              <Icon type={IconType.ServiceByInstruction} />
              {t("serviceRequests.serviceByInstruction.title")}
            </div>
            {!!customRequest.hasRequest && (
              <Status>
                {t(`serviceRequests.states.${customRequest.state}`)}
              </Status>
            )}
            <ArrowIcon type={IconType.Arrow} />
          </ParcelButton>
        </ServicesSection>
        {!hasInventory && (
          <ServicesSection>
            <ParcelButton
              onClick={() => openServicePanel("requestInventoryShipment")}
            >
              <div>
                <Icon type={IconType.PackOptions} />
                {t("serviceRequests.requestInventoryShipment.title")}
              </div>
              {requestInventoryShipmentStatus === "requested" && (
                <Status>
                  {t(`serviceRequests.states.pending_translation`)}
                </Status>
              )}
              <ArrowIcon type={IconType.Arrow} />
            </ParcelButton>
          </ServicesSection>
        )}
        <ServicesSection>
          <ParcelButton onClick={openPackOptions}>
            <div>
              <Icon type={IconType.PackOptions} />
              {t("serviceRequests.packOptions.title")}
            </div>
            {!!addonsCost && <Status>$ {addonsCost}</Status>}
            <ArrowIcon type={IconType.Arrow} />
          </ParcelButton>
        </ServicesSection>
        {hasInventory && (
          <ServicesSection>
            <ParcelButton onClick={openInventory}>
              <div>
                <Icon type={IconType.PackOptions} />
                {t("serviceRequests.inventory.title")}
              </div>
              <ArrowIcon type={IconType.Arrow} />
            </ParcelButton>
          </ServicesSection>
        )}
        {showTransferRequest && (
          <ServicesSection>
            <ParcelButton
              onClick={() => openServicePanel("requestTransferShipment")}
            >
              <div>
                <Icon type={IconType.PackOptions} />
                {requestTransferButtonTitle}
              </div>
              <ArrowIcon type={IconType.Arrow} />
            </ParcelButton>
          </ServicesSection>
        )}
        {isRemovableFromConsolidation && (
          <ServicesSection>
            <ParcelButton onClick={handleRemoveAllFromConsolidation}>
              <RemoveButton>
                <Icon type={IconType.Close} />
                {t("serviceRequests.removeFromConsolidation.actionText")}
              </RemoveButton>
            </ParcelButton>
          </ServicesSection>
        )}
        <ReturnSection>
          <ReturnButton>
            <div>
              <Icon type={IconType.Return} />
              {t("serviceRequests.returnPackage.title")}
            </div>
          </ReturnButton>
          <Tooltip
            body={
              <ReturnTooltip>
                {t("serviceRequests.returnPackage.writeUsFirstPart")}
                <a
                  href={`mailto:vsklad-returns@sklad.com?subject=Return package number ${description} with id ${id} to the store.`}
                >
                  vsklad-returns@sklad.com
                </a>
                {t("serviceRequests.returnPackage.writeUsSecondPart")}
              </ReturnTooltip>
            }
          >
            <HelpIcon type={IconType.Help} />
          </Tooltip>
        </ReturnSection>
      </div>
    );
  },
);

export default ServicesButtons;
