import { useEffect } from "react";

import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import CartAPI from "@/api/cart_api";
import { OutgoingAPI } from "@/api";

import { toastResponseError } from "@/utils/responseMessageHelper";

import useOutgoingStore from "@/store/useOutgoingStore";
import { requestNames } from "@/store/useServicesRequestStore";
import { useServicesStore } from "@/store";
import { DeliveryShippingMethodsResponseDto } from "@/types/api/cart";
import { CustomsDeclaration as CustomsDeclarationResponseDto } from "@/types/api/common";
import {
  OutgoingDetailedItemResponseDto,
  OutgoingDto,
  OutgoingResponseDto,
  OutgoingShipmentResponseDto,
  OutgoingType,
  UpdateConsolidationAddonsDto,
  UpdateConsolidationOptionalLineItemsDto,
} from "@/types/api/outgoing";
import { OutgoingQueryKey as QueryKey } from "@/types";

export const useOutgoing = (
  outgoingDto: OutgoingDto,
  options?: Omit<
    UseQueryOptions<AxiosResponse<OutgoingResponseDto[]>>,
    "queryKey" | "queryFn"
  >,
) => {
  const { updateIsLoadingOutgoing, outgoingTypes, updateOutgoingTypes } =
    useOutgoingStore();
  const fetchOutgoing = useQuery({
    queryFn: () => OutgoingAPI.getOutgoing(outgoingDto),
    queryKey: [QueryKey.Outgoing, outgoingDto],
    ...options,
  });

  const { data, error, isLoading, isSuccess } = fetchOutgoing;

  const oldItems = outgoingTypes[outgoingDto.type]?.items;

  useEffect(() => {
    updateIsLoadingOutgoing(outgoingDto.type, false);
    toastResponseError(error);
  }, [error]);

  useEffect(() => {
    updateIsLoadingOutgoing(outgoingDto.type, isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (isSuccess && data) {
      const outgoingResponseDto = data?.data;
      const outgoingHeaders = data?.headers;
      const total = outgoingHeaders?.["total"];
      const totalPrice = outgoingHeaders?.["total-price"];

      const newItemsIds = outgoingResponseDto.map((item) => item.id);
      const filteredOldItems = oldItems.filter(
        (item) => !newItemsIds.includes(item.id),
      );
      updateOutgoingTypes(outgoingDto.type, {
        page: outgoingDto.params.page,
        type: outgoingDto.type,
        total: total,
        totalPrice: totalPrice,
        items:
          outgoingDto.params.page === 1
            ? outgoingResponseDto
            : [...filteredOldItems, ...outgoingResponseDto],
      });
    }
  }, [data, isSuccess]);

  return fetchOutgoing;
};

const normalizeDetailed = (parcel: OutgoingDetailedItemResponseDto) => {
  const { items, packages, ...rootPackage } = parcel;

  const transformItems = items
    .map((item) => ({
      ...item,
      package_sku: item?.package?.sku ?? rootPackage.sku,
    }))
    .map((item) => {
      const serviceRequests = item.service_requests ?? [];
      const transformServiceRequests = serviceRequests.reduce(
        (requests, request) => {
          const requestType =
            requestNames[
              request.service_request_type.type.toLowerCase() as keyof typeof requestNames
            ];
          const status = request.processed_state;

          return { ...requests, [requestType]: status };
        },
        {},
      );

      return { ...item, service_request_map: transformServiceRequests };
    });

  if (!packages.length) {
    packages.unshift(rootPackage);
  }
  const packageList = packages.map((pkg) => {
    return {
      ...pkg,
      items: transformItems.filter((item) => item.package_sku === pkg?.sku),
    };
  });

  return {
    ...parcel,
    packages: packageList,
    items: transformItems,
    consolidation_addons: parcel?.consolidation_addons,
    estimate: {
      ...parcel.estimate,
      optional_lines_cost: +parcel.estimate.optional_lines_cost,
      items_addons_cost: +parcel.estimate.items_addons_cost,
      service_requests_cost: +parcel.estimate.service_requests_cost,
      consolidation_addons_cost: +parcel.estimate.consolidation_addons_cost,
    },
  };
};

export const useOutgoingDetailedItem = (
  id: number,
  options?: Omit<
    UseQueryOptions<OutgoingDetailedItemResponseDto>,
    "queryKey" | "queryFn"
  >,
) => {
  const { updateIsLoadingDetailedItem, updateDetailedItem, detailedItem } =
    useOutgoingStore();
  const fetchDetailedItem = useQuery({
    queryFn: () => OutgoingAPI.getDetailedItem(id),
    queryKey: [QueryKey.OutgoingDetailedItem, id],
    ...options,
  });

  const { data, isLoading, isSuccess, error } = fetchDetailedItem;

  useOutgoingCustomsDeclarations(id, {
    enabled: isSuccess,
  });

  useEffect(() => {
    updateIsLoadingDetailedItem(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (isSuccess && data) {
      updateIsLoadingDetailedItem(false);
      updateDetailedItem({
        ...detailedItem,
        [data.id]: normalizeDetailed(data),
      });
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (error) toastResponseError(error);
  }, [error]);

  return fetchDetailedItem;
};

const transformDetailedShipment = (shipment: OutgoingShipmentResponseDto) => {
  return {
    ...shipment,
    service_requests: shipment.service_requests.map((request: any) => ({
      ...request,
    })),
  };
};

export const useOutgoingDetailedShipment = (
  id: number,
  options?: Omit<
    UseQueryOptions<OutgoingShipmentResponseDto>,
    "queryKey" | "queryFn"
  >,
) => {
  const {
    updateIsLoadingDetailedShipment,
    updateDetailedShipment,
    detailedShipment,
  } = useOutgoingStore();
  const fetchDetailedShipment = useQuery({
    queryFn: () => OutgoingAPI.getShipment(id),
    queryKey: [QueryKey.OutgoingDetailedShipment, id],
    ...options,
  });

  const { data, isLoading, isSuccess } = fetchDetailedShipment;

  useEffect(() => {
    updateIsLoadingDetailedShipment(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (isSuccess && data) {
      updateIsLoadingDetailedShipment(false);
      updateDetailedShipment({
        ...detailedShipment,
        [data.id]: transformDetailedShipment(data),
      });
    }
  }, [data, isSuccess]);

  return fetchDetailedShipment;
};

export const useOutgoingUpdateComment = () => {
  const queryClient = useQueryClient();
  const { updateDetailedShipment, detailedShipment } = useOutgoingStore();

  const updateComment = useMutation({
    mutationFn: OutgoingAPI.updateComment,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.OutgoingUpdateComment],
      });
    },
    onSuccess: (OutgoingUpdateCommentResponseDto) => {
      if (
        OutgoingUpdateCommentResponseDto &&
        OutgoingUpdateCommentResponseDto.id
      ) {
        updateDetailedShipment({
          ...detailedShipment,
          [OutgoingUpdateCommentResponseDto.id]: {
            ...detailedShipment[OutgoingUpdateCommentResponseDto.id],
            customer_comment: OutgoingUpdateCommentResponseDto.customer_comment,
          },
        });
      }
    },
  });

  return updateComment;
};

export const useOutgoingCustomsDeclarations = (
  id: number,
  options?: Omit<
    UseQueryOptions<CustomsDeclarationResponseDto[]>,
    "queryKey" | "queryFn"
  >,
) => {
  const {
    updateDetailedItem,
    detailedItem,
    updateOutgoingTempDetailedItemId,
    updateIsLoadingCustomsDeclarations,
  } = useOutgoingStore();

  const fetchOutgoingDetailedShipmentQuery = useQuery({
    queryKey: [QueryKey.OutgoingCustomsDeclarations],
    queryFn: () => OutgoingAPI.getCustomsDeclarations(id),
    ...options,
  });

  const { data, isSuccess, isLoading, error } =
    fetchOutgoingDetailedShipmentQuery;

  useEffect(() => {
    updateOutgoingTempDetailedItemId(id);
  }, [id]);

  useEffect(() => {
    if (isSuccess && data) {
      updateDetailedItem({
        ...detailedItem,
        [id]: {
          ...detailedItem[id],
          customs_declarations: data,
        },
      });
    }
  }, [isSuccess, data]);

  useEffect(() => {
    updateIsLoadingCustomsDeclarations(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (error) toastResponseError(error);
  }, [error]);

  return fetchOutgoingDetailedShipmentQuery;
};

export const useOutgoingCreateCustomsDeclaration = () => {
  const queryClient = useQueryClient();
  const {
    tempDetailedItemId,
    updateDetailedItem,
    detailedItem,
    updateOutgoingTempDetailedItemId,
  } = useOutgoingStore();

  const createCustomsDeclaration = useMutation({
    mutationFn: OutgoingAPI.createCustomsDeclaration,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [
          QueryKey.OutgoingImportCustomInformation,
          tempDetailedItemId,
        ],
      });
    },
    onMutate: ({ itemId }) => {
      updateOutgoingTempDetailedItemId(itemId);
    },
    onSuccess: (customDeclarationDto) => {
      if (customDeclarationDto && detailedItem && tempDetailedItemId) {
        const customDeclarations =
          detailedItem[tempDetailedItemId]?.customs_declarations ?? [];

        updateDetailedItem({
          ...detailedItem,
          [tempDetailedItemId]: {
            ...detailedItem[tempDetailedItemId],
            customs_declarations: [...customDeclarations, customDeclarationDto],
          },
        });
      }
    },
  });

  return createCustomsDeclaration;
};

export const useOutgoingDeleteCustomsDeclaration = () => {
  const queryClient = useQueryClient();
  const { updateDetailedItem, detailedItem } = useOutgoingStore();

  const createCustomsDeclaration = useMutation({
    mutationFn: OutgoingAPI.deleteCustomsDeclaration,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.OutgoingImportCustomInformation],
      });
    },
    onSuccess: (_, variables) => {
      const customDeclarations =
        detailedItem[variables.itemId]?.customs_declarations ?? [];

      updateDetailedItem({
        ...detailedItem,
        [variables.itemId]: {
          ...detailedItem[variables.itemId],
          customs_declarations: customDeclarations.filter(
            (declaration) => declaration.id !== variables.id,
          ),
        },
      });
    },
  });

  return createCustomsDeclaration;
};

export const useOutgoingUpdateCustomsDeclaration = () => {
  const queryClient = useQueryClient();
  const { updateDetailedItem, detailedItem } = useOutgoingStore();

  const updateCustomsDeclaration = useMutation({
    mutationFn: OutgoingAPI.updateCustomsDeclaration,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.OutgoingUpdateCustomInformation],
      });
    },
    onSuccess: (customDeclarationDto, variables) => {
      const customDeclarations =
        detailedItem[variables.itemId]?.customs_declarations;

      if (customDeclarations) {
        updateDetailedItem({
          ...detailedItem,
          [variables.itemId]: {
            ...detailedItem[variables.itemId],
            customs_declarations: customDeclarations.map((declaration) =>
              declaration.id === variables.id
                ? customDeclarationDto.data
                : declaration,
            ),
          },
        });
      }
    },
  });

  return updateCustomsDeclaration;
};

export const useOutgoingImportCustomsDeclarations = () => {
  const queryClient = useQueryClient();

  const { updateIsLoadingCustomsDeclarations } = useOutgoingStore();

  const importCustomInformation = useMutation({
    mutationFn: CartAPI.importCustomsDeclaration,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.OutgoingImportCustomDeclarations],
      });
    },
    onMutate: () => {
      updateIsLoadingCustomsDeclarations(true);
    },
    onSuccess: () => {
      updateIsLoadingCustomsDeclarations(false);
    },
    onError: () => {
      updateIsLoadingCustomsDeclarations(false);
    },
  });

  return importCustomInformation;
};

export const useOutgoingUpdateDeliveryMethod = () => {
  const queryClient = useQueryClient();

  const {
    updateIsLoadingDetailedItem,
    updateDetailedItem,
    detailedItem,
    updateOutgoingTypes,
    outgoingTypes,
  } = useOutgoingStore();

  const updateDeliveryMethod = useMutation({
    mutationFn: OutgoingAPI.updateDeliveryMethod,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.OutgoingUpdateDeliveryMethods],
      });
    },
    onSuccess: (deliveryMethodDto) => {
      const consolidationId = deliveryMethodDto.data.id;
      const deliveryMethod = deliveryMethodDto.data.preferred_carrier;
      const estimate = deliveryMethodDto.data.estimate;
      const waitingForPaymentTotalPrice =
        deliveryMethodDto.headers["total-waiting-for-payment-price"];

      // updateDetailedItem({
      //   ...detailedItem,
      //   [consolidationId]: {
      //     ...consolidationDetailedItem,
      //     preferred_carrier: deliveryMethod,
      //     estimate,
      //   },
      // });

      // This manual update has "race condition" with useOutgoingDetailedItem hook and it's useEffect.
      // Instance of useOutgoingDetailedItem is created in ConsolidationPanel.
      // And on re-mounting ConsolidationPanel, useOutgoingDetailedItem useEffects are triggered and
      // they write stale data to updateDetailedItem
      // We need to either do 1) update react-query internal state or 2) invalidate queryKey and fetch data again

      // We are updating just SINGLE item by its queryKey ID, therefore changed data structure from code above
      queryClient.setQueryData(
        [QueryKey.OutgoingDetailedItem, consolidationId],
        {
          ...detailedItem[consolidationId],
          preferred_carrier: deliveryMethod,
          estimate,
        },
      );

      updateOutgoingTypes(OutgoingType.WaitingForPayment, {
        totalPrice: waitingForPaymentTotalPrice,
        items: outgoingTypes.waiting_for_payment.items.map((item) => {
          return item.id === consolidationId
            ? { ...item, preferred_carrier: deliveryMethod, estimate }
            : item;
        }),
      });
      updateOutgoingTypes(OutgoingType.PackingInProgress, {
        items: outgoingTypes.packing_in_progress.items.map((item) => {
          return item.id === consolidationId
            ? { ...item, preferred_carrier: deliveryMethod, estimate }
            : item;
        }),
      });
      updateOutgoingTypes(OutgoingType.WaitingForInformation, {
        items: outgoingTypes.waiting_for_information.items.map((item) => {
          return item.id === consolidationId
            ? { ...item, preferred_carrier: deliveryMethod, estimate }
            : item;
        }),
      });
      updateOutgoingTypes(OutgoingType.Paid, {
        items: outgoingTypes.paid.items.map((item) => {
          return item.id === consolidationId
            ? { ...item, preferred_carrier: deliveryMethod, estimate }
            : item;
        }),
      });

      updateIsLoadingDetailedItem(false);
    },
  });

  return updateDeliveryMethod;
};

export const useOutgoingUpdateAddress = () => {
  const queryClient = useQueryClient();

  const { detailedItem } = useOutgoingStore();

  const updateAddress = useMutation({
    mutationFn: OutgoingAPI.updateAddress,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.OutgoingUpdateAddress],
      });
    },
    onSuccess: (updateAddressDto) => {
      const id = updateAddressDto.data.id;

      if (!id) return;

      // This manual update has "race condition" with useOutgoingDetailedItem hook and it's useEffect.
      // Instance of useOutgoingDetailedItem is created in ConsolidationPanel.
      // And on re-mounting ConsolidationPanel, useOutgoingDetailedItem useEffects are triggered and
      // they write stale data to updateDetailedItem
      // We need to either do 1) update react-query internal state or 2) invalidate queryKey and fetch data again

      // const updDetailedItem = updateAddressDto.data;
      // updateDetailedItem({
      //   ...detailedItem,
      //   [id]: { ...detailedItem[id], ...updDetailedItem },
      // });

      // Hotfix until react-query re-architecture :
      // Instead of doing FE state update which is commented below we just need to reload the data
      // from BE by invalidating queryKey:
      // queryClient.invalidateQueries({
      //   queryKey: [QueryKey.OutgoingDetailedItem, id],
      // });

      // Or we mutate cached data directly to react-query (which will by side effects call updateDetailedItem).
      // We are updating just SINGLE item by its queryKey ID, therefore changed data structure from code above
      const updDetailedItem = updateAddressDto.data;
      queryClient.setQueryData([QueryKey.OutgoingDetailedItem, id], {
        ...detailedItem[id],
        ...updDetailedItem,
      });
    },
  });

  return updateAddress;
};

export const useOutgoingCancelConsolidation = () => {
  const queryClient = useQueryClient();

  const { updateOutgoingTypes, outgoingTypes } = useOutgoingStore();

  const cancelConsolidation = useMutation({
    mutationFn: OutgoingAPI.cancelConsolidation,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.OutgoingCancelConsolidation],
      });
    },
    onSuccess: (cancelConsolidationDto) => {
      Object.values(OutgoingType).map((type) => {
        const updItems = outgoingTypes[type].items.filter(
          (item) => item.id !== cancelConsolidationDto.data.consolidation_id,
        );

        updateOutgoingTypes(type, {
          items: updItems,
          total: updItems.length,
        });
      });
    },
  });

  return cancelConsolidation;
};

export const useOutgoingUpdateRequireInsurance = () => {
  const queryClient = useQueryClient();

  const {
    updateDetailedItem,
    detailedItem,
    updateIsLoadingUpdateInsurance,
    updateOutgoingTypes,
    outgoingTypes,
  } = useOutgoingStore();

  const updateRequireInsurance = useMutation({
    mutationFn: OutgoingAPI.updateRequireInsurance,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.OutgoingUpdateRequireInsurance],
      });
    },
    onMutate: () => {
      updateIsLoadingUpdateInsurance(true);
    },
    onSuccess: (insuranceDto) => {
      const estimate = insuranceDto.data;
      const consolidation_id = estimate.consolidation_id;

      const insurance = {
        ...estimate,
        optional_lines_cost: +estimate.optional_lines_cost,
        items_addons_cost: +estimate.items_addons_cost,
        service_requests_cost: +estimate.service_requests_cost,
        consolidation_addons_cost: +estimate.consolidation_addons_cost,
      };

      updateDetailedItem({
        ...detailedItem,
        [consolidation_id]: {
          ...detailedItem[consolidation_id],
          estimate: insurance,
        },
      });
      updateOutgoingTypes(OutgoingType.WaitingForPayment, {
        items: outgoingTypes.waiting_for_payment.items.map((item) =>
          item.id !== consolidation_id
            ? item
            : { ...item, estimate: insurance },
        ),
      });

      updateIsLoadingUpdateInsurance(false);
    },
  });

  return updateRequireInsurance;
};

export const useOutgoingDeliveryMethods = (
  id?: number,
  options?: Omit<
    UseQueryOptions<DeliveryShippingMethodsResponseDto[]>,
    "queryKey" | "queryFn"
  >,
) => {
  if (!id) return;

  const { updateIsLoadingDetailedItem, updateDeliveryMethods } =
    useOutgoingStore();
  const fetchDeliveryMethods = useQuery({
    queryFn: () => OutgoingAPI.getDeliveryMethods(id),
    queryKey: [QueryKey.OutgoingDeliveryMethods, id],
    ...options,
  });

  const { data, isLoading, isSuccess } = fetchDeliveryMethods;

  useEffect(() => {
    updateIsLoadingDetailedItem(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (isSuccess && data) {
      updateIsLoadingDetailedItem(false);
      updateDeliveryMethods(data);
    }
  }, [data, isSuccess]);

  return fetchDeliveryMethods;
};

export const useOutgoingUpdateConsolidationAddons = () => {
  const queryClient = useQueryClient();

  const { detailedItem } = useOutgoingStore();
  const { consolidationAddonsMethods } = useServicesStore();

  const updateConsolidationAddons = useMutation({
    mutationFn: async ({
      addonsDto,
      optionalLineDto,
    }: {
      addonsDto: UpdateConsolidationAddonsDto;
      optionalLineDto: UpdateConsolidationOptionalLineItemsDto;
    }) => {
      const req1 = OutgoingAPI.updateConsolidationAddons(addonsDto);
      const req2 =
        OutgoingAPI.updateConsolidationOptionalLineItems(optionalLineDto);
      await Promise.all([req1, req2]);

      const resp1 = await req1;
      const resp2 = await req2;

      return Promise.resolve({
        addonsResponse: resp1.data,
        optionalLineResponse: resp2.data,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.OutgoingUpdateConsolidationAddons],
      });
    },
    onSuccess: ({ addonsResponse, optionalLineResponse }) => {
      const data = addonsResponse;

      const consolidationId = data.id;
      const addonsCodes = data.addons_codes;
      const estimate = data.estimate;

      // This manual update has "race condition" with useOutgoingDetailedItem hook and it's useEffect.
      // Instance of useOutgoingDetailedItem is created in ConsolidationPanel.
      // And on re-mounting ConsolidationPanel, useOutgoingDetailedItem useEffects are triggered and
      // they write stale data to updateDetailedItem
      // We need to either do 1) update react-query internal state or 2) invalidate queryKey and fetch data again

      // updateDetailedItem({
      //   ...detailedItem,
      //   [consolidationId]: {
      //     ...detailedItem[consolidationId],
      //     consolidations_addons: addonsCodes,
      //     estimate,
      //   },
      // });

      const availableAddons = consolidationAddonsMethods;
      const updateAddons = addonsCodes.map((x: string) =>
        availableAddons.find((item) => item.code === x),
      );

      // We are updating just SINGLE item by its queryKey ID, therefore changed data structure from code above
      queryClient.setQueryData(
        [QueryKey.OutgoingDetailedItem, consolidationId],
        {
          ...detailedItem[consolidationId],
          consolidation_addons: updateAddons,
          estimate,
          optional_line_item_codes: optionalLineResponse,
        },
      );
    },
  });

  return updateConsolidationAddons;
};

export const useOutgoingRemoveItemFromConsolidation = () => {
  const queryClient = useQueryClient();

  const {
    updateDetailedItem,
    detailedItem,
    updateOutgoingTypes,
    outgoingTypes,
  } = useOutgoingStore();

  const removeItemFromConsolidation = useMutation({
    mutationFn: OutgoingAPI.removeItemFromConsolidation,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.OutgoingRemoveItemFromConsolidation],
      });
    },
    onSuccess: (_, { id, consolidationId }) => {
      const shipmentId = (detailedItem[consolidationId]?.items ?? []).find(
        (item) => item.id === id,
      )?.shipment_id;

      if (!shipmentId) return;

      const removedItemWeight =
        (detailedItem[shipmentId]?.items ?? []).find((item) => item.id === id)
          ?.weight ?? 0;

      updateDetailedItem({
        ...detailedItem,
        [consolidationId]: {
          ...detailedItem[consolidationId],
          items: detailedItem[consolidationId].items?.filter(
            (item) => item.id !== id,
          ),
        },
      });
      updateOutgoingTypes(OutgoingType.PackingInProgress, {
        items: outgoingTypes.packing_in_progress.items.map((item) => {
          return item.id !== consolidationId
            ? item
            : {
                ...item,
                total_weight:
                  item.total_weight - removedItemWeight > 0
                    ? item.total_weight
                    : 0,
              };
        }),
      });
    },
  });

  return removeItemFromConsolidation;
};
