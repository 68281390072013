import React, { FC, useCallback } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import {
  Content,
  ContentWrapper,
  Heading,
} from "@/containers/RightPanel/RightPanel.styles";
import { CopyToClipboard } from "@/components/CopyToClipboard";
import { Icon } from "@/components/Icon";
import { Input } from "@/components/Input";
import { PriceBlock } from "@/components/PriceBlock";

import { Spinner } from "@/styles/common";
import {
  Comment,
  Form,
  Message,
  MessageIcon,
  MessageWrapper,
  Paragraph,
  Title,
} from "@/styles/serviceRequest";

import {
  useCompleteRequestTransferShipment,
  useCreateRequestTransferShipment,
} from "@/hooks/react-query/servicesRequests";

import {
  RightPanelType,
  StorageItemPanelData,
} from "@/store/useRightPanelStore";
import useServicesRequestStore, {
  requestNames,
} from "@/store/useServicesRequestStore";
import { useRightPanelStore, useStorageStore } from "@/store";
import { ButtonColor, ButtonVariant, IconType } from "@/enums";
import { ServiceRequestProps } from "@/types/StorageForms/ServiceRequest";

import RequestInfo from "../RequestInfo/RequestInfo";
import {
  getRequestInfo,
  getRequestInventoryInfo,
  serviceRequestFormik,
} from "./helpers";
import {
  Price,
  SubmitButton,
  TransferCodeWrapper,
  TransferWrapper,
} from "./ServiceRequest.styles";

const ServiceRequest: FC<ServiceRequestProps> = React.memo(() => {
  const { t } = useTranslation("common");
  const { panelData, openRightPanel, updatePanelData } = useRightPanelStore();
  const { isLoadingShipment: isLoading } = useStorageStore();
  const { types: serviceTypes, transferRequests } = useServicesRequestStore();
  const { mutateAsync: createRequestTransferShipment } =
    useCreateRequestTransferShipment();
  const { mutateAsync: completeTransferShipment } =
    useCompleteRequestTransferShipment();
  const queryClient = useQueryClient();

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik(
    serviceRequestFormik({
      t,
      openRightPanel,
      updatePanelData,
      panelData,
      serviceTypes,
      createRequestTransferShipment,
      completeTransferShipment,
      queryClient,
    }),
  );

  const handleClose = useCallback(
    () =>
      openRightPanel(
        RightPanelType.STORAGE_ITEM,
        panelData as StorageItemPanelData,
      ),
    [panelData, openRightPanel],
  );

  const id = panelData.id ?? "";
  const readOnly = panelData.readOnly;
  const requestType = panelData.requestType;
  const isRequestInventoryShipment = requestType === "requestInventoryShipment";
  const isRequestTransferShipment = requestType === "requestTransferShipment";
  const isRequestAdditionalPhotos = requestType === "additionalPhotos";

  // Conditional sections
  const showSecondParagraph = isRequestInventoryShipment;
  const showDefaultMessageAndCommentSection =
    !isRequestInventoryShipment && !isRequestTransferShipment;
  const showComment = !isRequestInventoryShipment && !isRequestAdditionalPhotos;
  const showPriceSection = !isRequestTransferShipment;
  const activeTransferRequest = transferRequests?.[id];
  const isActiveTransferRequest =
    !!activeTransferRequest && activeTransferRequest.status === "requested";
  const isClientTransferring = panelData.state === "client_transferring";

  let requestCost;
  let costSuffix = "";
  const requestInfo = isRequestInventoryShipment
    ? getRequestInventoryInfo(
        panelData?.requestInventoryShipmentData.unpacking_task ?? {},
      )
    : getRequestInfo(panelData.requests, requestType ?? "");

  const hasRequest = requestInfo.hasRequest;

  const paragraphText =
    isRequestTransferShipment && isClientTransferring
      ? t(`serviceRequests.${requestType}.paragraphClientTransferring`)
      : t(`serviceRequests.${requestType}.paragraph`);

  const labelText =
    isRequestTransferShipment && isClientTransferring
      ? t("serviceRequests.requestTransferShipment.transferPartnerCode")
      : t("serviceRequests.requestTransferShipment.transferPartnerId");

  let submitTitle = requestInfo.isRequestCancelable
    ? t("common.cancelRequest")
    : t(`serviceRequests.${requestType}.submit`);

  if (isActiveTransferRequest) {
    submitTitle = t(`serviceRequests.requestTransferShipment.processed`);
  }

  if (isRequestTransferShipment) {
    // noop
  } else if (isRequestInventoryShipment) {
    requestCost = t(`serviceRequests.${requestType}.cost`);
    costSuffix = t(`serviceRequests.${requestType}.costSuffix`);
  } else {
    requestCost = serviceTypes.find(
      (request) =>
        requestNames[request.type as keyof typeof requestNames] === requestType,
    )?.cost;
  }

  const handleSubmitButtonClick = () => {
    if (isActiveTransferRequest) return;
    handleSubmit();
  };

  return (
    <ContentWrapper>
      <Spinner isActive={isLoading} />
      <Heading $isBordered>
        <button onClick={handleClose}>
          <Icon type={IconType.Arrow} />
        </button>
        <Title>{t(`serviceRequests.${requestType}.title`)}</Title>
      </Heading>
      {isLoading || (
        <Content>
          <Form onSubmit={handleSubmit}>
            {hasRequest ? (
              <RequestInfo request={requestInfo} />
            ) : (
              <div>
                <Paragraph>{paragraphText}</Paragraph>
                {showSecondParagraph && (
                  <Paragraph style={{ marginTop: "-15px" }}>
                    {t(`serviceRequests.${requestType}.paragraph2`)}
                  </Paragraph>
                )}
                {showDefaultMessageAndCommentSection && (
                  <>
                    <MessageWrapper>
                      <MessageIcon type={requestType as IconType} />
                      <Message>
                        {t(`serviceRequests.${requestType}.message`)}
                      </Message>
                    </MessageWrapper>
                    {showComment && (
                      <Comment
                        name="comment"
                        placeholder={t("serviceRequests.placeholderComment")}
                        value={values.comment}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        tooltip={errors.comment}
                        invalid={!!errors.comment && !!touched.comment}
                        disabled={!!readOnly}
                      />
                    )}
                  </>
                )}
                {showPriceSection && (
                  <Price>
                    <PriceBlock
                      price={requestCost ?? 0}
                      title={t(`serviceRequests.requestCost`)}
                      suffix={costSuffix}
                    />
                  </Price>
                )}
                {isRequestTransferShipment && (
                  <>
                    <TransferWrapper>
                      <p>{labelText}</p>
                      <Input
                        name="partnerTransferId"
                        value={values.partnerTransferId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        tooltip={errors.partnerTransferId}
                        invalid={!!errors.partnerTransferId}
                      />
                    </TransferWrapper>
                    {isActiveTransferRequest && (
                      <TransferCodeWrapper>
                        <div>Transfer code</div>
                        <CopyToClipboard
                          text={activeTransferRequest.transferCode}
                        >
                          <h2>{activeTransferRequest.transferCode}</h2>
                        </CopyToClipboard>
                      </TransferCodeWrapper>
                    )}
                  </>
                )}
              </div>
            )}
            {!readOnly && (
              <SubmitButton
                onClick={handleSubmitButtonClick}
                isLoading={isSubmitting}
                disabled={isSubmitting}
                hidden={!!requestInfo.isSubmitHidden}
                color={
                  requestInfo.isRequestCancelable
                    ? ButtonColor.Red
                    : isActiveTransferRequest
                      ? ButtonColor.Secondary
                      : ButtonColor.Primary
                }
                variant={ButtonVariant.Filled}
              >
                {submitTitle}
              </SubmitButton>
            )}
          </Form>
        </Content>
      )}
    </ContentWrapper>
  );
});

export default ServiceRequest;
