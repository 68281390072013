import React, {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import { isMobile } from "react-device-detect";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import PinchZoom from "react-quick-pinch-zoom/esm/PinchZoom/component";

import { useDebouncedCallback } from "@/hooks";

import { useFullScreenImageStore } from "@/store";
import { Image as ImageType } from "@/types";

import {
  Image,
  ImageOverviewContainer,
  ImagesListContainer,
  ImagesListContent,
  ImageThumbnail,
  MediumImageWrapper,
  OverlayImage,
  Wrapper,
  ZoomArea,
} from "./ImageViewer2.styles";

interface ImageViewer2Props {
  images: ImageType[];
  sideBarWidth?: number;
  zoomLevel?: number;
  hideSideBar?: boolean;
  preventImageZoom?: boolean;
}

const ImageViewer2: FC<ImageViewer2Props> = React.memo(
  ({
    images,
    sideBarWidth: sideBarWidthProp = 100,
    hideSideBar = false,
    zoomLevel = 2,
    preventImageZoom,
  }) => {
    const {
      showFullScreenImage: showFullscreenImageProp,
      hideFullScreenImage,
      updateFullScreenImagePosition,
      updateFullScreenImageSource,
      isVisibleFullScreenImage,
    } = useFullScreenImageStore();
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [hoverSize, setHoverSize] = useState({ w: 100, h: 150 });
    const [size, setSize] = useState({ w: 0, h: 0 });
    const [hoverOffset, setHoverOffset] = useState({ x: 0, y: 0 });
    const sideBarWidth = hideSideBar ? 0 : sideBarWidthProp;
    const isZoomEnabled = zoomLevel === 1 ? false : isVisibleFullScreenImage;
    const showFullscreenImage = (zoomLevel: number) => {
      if (!preventImageZoom) showFullscreenImageProp(zoomLevel);
    };

    const imgRef = useRef<HTMLImageElement>(null);
    const quickPinchZoomRef = useRef<PinchZoom>(null);
    const [node, setNode] = useState<HTMLDivElement | null>(null);

    const getMediumImageSource = (img: any) => {
      if (img.medium) return img.medium;
      if (img.imager_medium_url) return img.imager_medium_url;
    };

    const getLargeImageSource = (img: any) => {
      if (img.large) return img.large;
      if (img.imager_url) return img.imager_url;
    };

    const measureZoomAreaSize = (node: HTMLDivElement) => {
      if (node) {
        const size = { w: node.clientWidth, h: node.clientHeight };
        const currentRect = node.getBoundingClientRect();
        if (node.parentNode instanceof HTMLElement) {
          const parentRect = node.parentNode.getBoundingClientRect();
          const hoverOffset = {
            x: currentRect.x - parentRect.x,
            y: currentRect.y - parentRect.y,
          };

          setSize(size);
          setHoverOffset(hoverOffset);
          setHoverSize({ w: size.w / zoomLevel, h: size.h / zoomLevel });
        }
      }
    };

    const measuredRef = useCallback(
      (node: HTMLDivElement) => {
        if (node !== null) {
          setNode(node);
          measureZoomAreaSize(node);
        }
      },
      [node, zoomLevel],
    );

    useLayoutEffect(() => {
      if (node) {
        const measure = () => {
          measureZoomAreaSize(node);
        };

        window.addEventListener("resize", measure);

        return () => {
          window.removeEventListener("resize", measure);
        };
      }
    }, [node, zoomLevel]);

    const onSelectImage = useCallback(
      (index: number) => {
        if (quickPinchZoomRef.current)
          quickPinchZoomRef.current.scaleTo({ x: 0, y: 0, scale: 1 });
        setSelectedImageIndex(index);
      },
      [setSelectedImageIndex, updateFullScreenImageSource, quickPinchZoomRef],
    );

    const renderThumb = (index: number, image: string) => {
      return (
        <ImageThumbnail
          key={index}
          onClick={(e) => onSelectImage(index)}
          $isSelected={index === selectedImageIndex}
        >
          <Image src={image} />
        </ImageThumbnail>
      );
    };

    const renderImagesList = () => {
      return (
        <ImagesListContent>
          {images.map((img, index) => {
            const imgSrc =
              typeof img === "string" ? img : getMediumImageSource(img);
            return renderThumb(index, imgSrc);
          })}
        </ImagesListContent>
      );
    };

    const getMousePos = (e: MouseEvent) => {
      var x = e.nativeEvent.offsetX;
      var y = e.nativeEvent.offsetY;
      return { x: x, y: y };
    };

    const onMouseMove = useCallback(
      (e: React.MouseEvent<HTMLImageElement>) => {
        e.stopPropagation();
        e.preventDefault();

        const position = getMousePos(e);

        const w = size.w - hoverSize.w;
        const h = size.h - hoverSize.h;

        const pos = {
          x: position.x - hoverSize.w / 2,
          y: position.y - hoverSize.h / 2,
        };

        const left = Math.min(Math.max(0, pos.x), w);
        const top = Math.min(Math.max(0, pos.y), h);

        const relativeLeft = left / size.w;
        const relativeTop = top / size.h;

        const relativePosition = { x: relativeLeft, y: relativeTop };
        setPosition(relativePosition);
        updateFullScreenImagePosition(relativePosition);
      },
      [setPosition, updateFullScreenImagePosition, size, hoverSize],
    );

    const showImageInPopup = useCallback(() => {
      const selectedImage = images[selectedImageIndex];
      if (typeof selectedImage === "object") {
        updateFullScreenImageSource(getLargeImageSource(selectedImage));
      }
      showFullscreenImage(zoomLevel);
    }, [
      images,
      showFullscreenImage,
      selectedImageIndex,
      size,
      hoverSize,
      zoomLevel,
    ]);

    useEffect(() => {
      const selectedImage = images[selectedImageIndex];
      if (typeof selectedImage === "object") {
        updateFullScreenImageSource(getLargeImageSource(selectedImage));
      }
    }, [images, updateFullScreenImageSource, selectedImageIndex]);

    const [debouncedShowImageInPopup, cancelDebouncedCallback] =
      useDebouncedCallback(
        () => {
          showImageInPopup();
        },
        200,
        [
          images,
          showFullscreenImage,
          selectedImageIndex,
          size,
          hoverSize,
          zoomLevel,
        ],
      );

    const handleOnMouseLeave = useCallback(
      (e: React.MouseEvent<HTMLImageElement>) => {
        cancelDebouncedCallback();
        hideFullScreenImage();
      },
      [hideFullScreenImage, cancelDebouncedCallback],
    );

    const handleOnMouseEnter = useCallback(
      (e: React.MouseEvent<HTMLImageElement>) => {
        debouncedShowImageInPopup();
      },
      [debouncedShowImageInPopup],
    );

    const onUpdateByGestures = useCallback(
      ({ x, y, scale }: { x: number; y: number; scale: number }) => {
        const { current: img } = imgRef;

        if (img) {
          const value = make3dTransformValue({ x, y, scale });
          img.style.setProperty("transform", value);
        }
      },
      [],
    );

    const selectedImage = images[selectedImageIndex];
    const overlayImageSrc =
      typeof selectedImage === "object"
        ? getMediumImageSource(selectedImage)
        : selectedImage;

    const handleImageCarousel = () => {
      const nextPictureIndex =
        selectedImageIndex + 1 < images.length ? selectedImageIndex + 1 : 0;
      if (selectedImageIndex !== nextPictureIndex) {
        setSelectedImageIndex(nextPictureIndex);
      }
    };

    return (
      <Wrapper onClick={hideSideBar ? handleImageCarousel : undefined}>
        <ImageOverviewContainer $sideBarWidth={sideBarWidth}>
          <MediumImageWrapper ref={measuredRef}>
            <QuickPinchZoom
              ref={quickPinchZoomRef}
              enabled={isMobile}
              onUpdate={onUpdateByGestures}
            >
              <OverlayImage
                ref={imgRef}
                src={overlayImageSrc}
                {...(!isMobile && { onMouseEnter: handleOnMouseEnter })}
                {...(!isMobile && { onMouseMove: onMouseMove })}
                {...(!isMobile && { onMouseLeave: handleOnMouseLeave })}
                onLoad={() => {
                  if (node) measureZoomAreaSize(node);
                }}
              />
            </QuickPinchZoom>
            {!isMobile && (
              <ZoomArea
                $isVisible={isZoomEnabled}
                position={position}
                $parentSize={size}
                $hoverSize={hoverSize}
                $hoverOffset={hoverOffset}
              />
            )}
          </MediumImageWrapper>
        </ImageOverviewContainer>
        <ImagesListContainer $sideBarWidth={sideBarWidth}>
          {renderImagesList()}
        </ImagesListContainer>
      </Wrapper>
    );
  },
);

export default ImageViewer2;
