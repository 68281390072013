import React, { FC, useCallback } from "react";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import {
  BackButton,
  ContentWrapper,
  Heading,
  LeftArrowIcon,
} from "@/containers/RightPanel/RightPanel.styles";
import { AddressSearch } from "@/components/Addresses/components";
import { Button } from "@/components/Button";

import { useCartSetAddress } from "@/hooks/react-query/cart";
import { useOutgoingUpdateAddress } from "@/hooks/react-query/outgoing";

import useOutgoingStore from "@/store/useOutgoingStore";
import { RightPanelType } from "@/store/useRightPanelStore";
import { useDestinationsStore, useRightPanelStore } from "@/store";
import { ButtonColor, ButtonType, ButtonVariant, IconType } from "@/enums";
import { AddressDto } from "@/types/api/destinations";
import { ChangingAddressFormProps } from "@/types/OutgoingForms/changingAddressPanel";

import {
  AddButton,
  AddressesListWrapper,
  ButtonWrapper,
  ContentContainer,
  Form,
} from "./ChangingAddressPanel.styles";
import { changingAddressPanelFormik } from "./helpers";

const ChangingAddressPanel: FC<ChangingAddressFormProps> = React.memo(
  ({ panelData }) => {
    const { t } = useTranslation("common");
    const { openRightPanel } = useRightPanelStore();
    const { detailedItem } = useOutgoingStore();
    const { addresses: destinations } = useDestinationsStore();
    const { mutateAsync: setCartAddress } = useCartSetAddress();
    const { mutateAsync: updateAddress } = useOutgoingUpdateAddress();
    const {
      handleSubmit,
      values: { addressId },
      isSubmitting,
      setFieldValue,
    } = useFormik(
      changingAddressPanelFormik(
        panelData,
        destinations,
        updateAddress,
        setCartAddress,
        openRightPanel,
      ),
    );
    const isCartVariant = panelData.isCartVariant;
    const returnPreviousPanel = panelData.returnPreviousPanel;
    const id = panelData.detailedItemID;
    const detailed = isCartVariant
      ? panelData.cart
      : id
        ? detailedItem[id]
        : {};

    const panelProps = {
      initialPanelData: panelData,
      returnPreviousPanel: RightPanelType.CHANGING_ADDRESS,
    };
    const handleBack = useCallback(
      () => openRightPanel(returnPreviousPanel, panelData),
      [openRightPanel, panelData],
    );
    const handleAddressSelect = useCallback(
      (id: string | number) =>
        setFieldValue("addressId", addressId === id ? null : id),
      [addressId, setFieldValue],
    );

    const handleAddressSubmit = async (id: number) => {
      return handleSubmit();
    };

    const handleNewAddressCreated = useCallback(
      async (newAddress: AddressDto) => {
        const updateMethod = isCartVariant
          ? setCartAddress(newAddress.id)
          : updateAddress({
              id: panelData.detailedItemID!,
              destination_address: newAddress,
            });
        updateMethod.then(() => {
          handleBack();
          return Promise.resolve();
        });
      },
      [isCartVariant, setCartAddress, updateAddress, panelData],
    );

    return (
      <ContentWrapper>
        <Heading $isBordered>
          <BackButton color={ButtonColor.Black50} onClick={handleBack}>
            <LeftArrowIcon type={IconType.Arrow} />
            {detailed?.sku}:
          </BackButton>
          {t("parcels.changingAddress")}
        </Heading>
        <ContentContainer>
          <AddressSearch />
          <Form onSubmit={handleSubmit}>
            <AddressesListWrapper
              editable
              onSelect={handleAddressSelect}
              onSubmit={handleAddressSubmit}
              selectedAddress={addressId}
              {...panelProps}
            />
            <AddButton
              {...panelProps}
              onNewAddressCreated={handleNewAddressCreated}
            />
            <ButtonWrapper>
              <Button
                type={ButtonType.Submit}
                color={ButtonColor.Primary}
                variant={ButtonVariant.Filled}
                isLoading={isSubmitting}
                disabled={!addressId || isSubmitting}
              >
                {t("common.saveChanges")}
              </Button>
            </ButtonWrapper>
          </Form>
        </ContentContainer>
      </ContentWrapper>
    );
  },
);

export default ChangingAddressPanel;
