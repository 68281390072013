import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAddonsIconsImage } from "@/containers/Delivery/helpers";
import { Icon, IconPng } from "@/components/Icon";
import { ImageViewer2 } from "@/components/ImageViewer2";
import { PackageSizes } from "@/components/PackageSizes";
import { PackagesList } from "@/components/PackagesList";
import { PackagesPagination } from "@/components/PackagesPagination";
import { ServiceLogo } from "@/components/ServiceLogo";

import {
  ExcessMarker,
  HeaderTitle,
  InnerSection,
  PayButton,
  Section,
  SectionContent,
  SectionHeader,
} from "@/styles/outgoing";
import { ChangeButton } from "@/styles/parcels";
import { theme } from "@/styles";

import formatter from "@/utils/formatter";
import { useOutgoingUpdateComment } from "@/hooks/react-query/outgoing";
import {
  useAddonsMethods,
  useConsolidationAddonsMethods,
  usePackingMethods,
} from "@/hooks/react-query/services";

import useOutgoingStore from "@/store/useOutgoingStore";
import { RightPanelType } from "@/store/useRightPanelStore";
import { useAuthStore, useRightPanelStore, useServicesStore } from "@/store";
import { ButtonColor, ButtonVariant, IconType } from "@/enums";
import { AddressDto } from "@/types/api/destinations";

import {
  CancelButton,
  CancelIcon,
  ContentWrapper,
  Footer,
  ImageSection,
  PanelAddressItem,
  SKUTitle,
} from "./ParcelInfoTab.styles";

interface ParcelInfoTabProps {
  id: string | number;
  onPackingCancel?: () => void;
}

const ParcelInfoTab: FC<ParcelInfoTabProps> = React.memo(
  ({ id, onPackingCancel }) => {
    const { t } = useTranslation("common");
    const {
      userAuth: { display_weight_in: units },
    } = useAuthStore();
    const { detailedItem } = useOutgoingStore();
    const { panelData, openRightPanel } = useRightPanelStore();
    const { packingMethods, getSelectAddons, consolidationAddonsMethods } =
      useServicesStore();

    const addonsMethods = getSelectAddons();

    const { mutateAsync: updateComment } = useOutgoingUpdateComment();

    usePackingMethods({ enabled: !packingMethods.length });
    useAddonsMethods({ enabled: !addonsMethods.length });
    useConsolidationAddonsMethods({
      enabled: !consolidationAddonsMethods.length,
    });

    const contentRef = useRef<HTMLDivElement>(null);
    const [selectedPackageIndex, setSelectedPackageIndex] = useState(0);
    const detailed = detailedItem[+id];
    const delivery = detailed?.preferred_carrier ?? "";
    const state = detailed?.state ?? "";
    const packingCode = detailed?.optional_line_item_codes?.[0] ?? "";
    const isPaid = state === "paid";
    const disabledChange =
      ["paid", "claimed_for_packing", "picked", "claimed"].indexOf(state) !==
      -1;
    const disabledChangeForPackingGrade = disabledChange || state === "packed";
    const disabledChangeForShippingMethod =
      disabledChange || state !== "pending";
    const hideFooterAction =
      ["claimed_for_packing", "picked", "claimed"].indexOf(state) !== -1;
    const showPackedParcelInformation =
      ["paid", "packed"].indexOf(state) !== -1;
    const showAllPackagesList = !showPackedParcelInformation;
    const showPayButton = state !== "pending" && !isPaid;
    const packing = packingMethods.find(
      (packingMethod) => packingMethod.code === packingCode,
    );
    const packingTitle = packing?.title ?? "";
    const services = !!packingMethods.length;
    const foundPackageId = panelData.foundPackageId ?? 0;
    const excessItemsOriginal = panelData.excessItems;
    const excessItems =
      !!excessItemsOriginal &&
      excessItemsOriginal.map((item: any) => item.item);
    const isHideFooter = hideFooterAction;

    const packages = detailed?.packages ?? [];
    const hasFewPackages = packages.length > 1;
    const items = detailed?.items;

    useEffect(() => {
      if (contentRef.current && foundPackageId)
        contentRef.current.scrollIntoView();
    }, [contentRef.current, foundPackageId]);

    const clickChangingAddress = useCallback(() => {
      openRightPanel(RightPanelType.CHANGING_ADDRESS, {
        ...panelData,
        addressId: detailed?.destination_address_id,
      });
    }, [openRightPanel, panelData, detailed]);

    const editDeliveryMethod = useCallback(
      () =>
        openRightPanel(RightPanelType.DELIVERY_METHODS, {
          ...panelData,
          deliveryCountry: detailed?.country,
          deliveryMethod: delivery,
        }),
      [openRightPanel, panelData, detailed, delivery],
    );

    const editPackingGrade = useCallback(
      () =>
        openRightPanel(RightPanelType.PACKING_GRADE, {
          ...panelData,
          deliveryMethod: delivery,
          packingCode,
          packingMethods,
          selectedConsolidationAddons: detailed?.consolidation_addons?.map(
            (x: any) => x.code,
          ),
          consolidationSku: detailed?.sku,
        }),
      [
        openRightPanel,
        panelData,
        detailed,
        delivery,
        packingCode,
        packingMethods,
      ],
    );

    const clickPackagesItem = (item: any) => {
      openRightPanel(RightPanelType.STORAGE_ITEM, {
        readOnly: true,
        id: item.shipment_id,
        originalItem: item,
        detailedItemID: +id,
        consolidationSku: detailed?.sku,
        emblem_thumb_url: item.emblem_thumb_url,
        description: item.shipment_tracking_number,
        returnPreviousPanel: panelData.returnPreviousPanel,
        excessItems: panelData.excessItems,
        rootReturnPanel: panelData.returnPreviousPanel,
        getShipmentType: "OutgoingDetailedShipment",
        updateComment,
        isItemInCart: true,
      });
    };

    const handleSetSelectPackageIndex = useCallback(
      (index: number) => {
        setSelectedPackageIndex(index);
      },
      [setSelectedPackageIndex],
    );

    const clickReviewAndPay = useCallback(() => {
      openRightPanel(RightPanelType.CONFIRM_PAYMENT_DETAILS, panelData);
    }, [openRightPanel, panelData]);

    const clickAlreadyPayed = useCallback(() => {
      openRightPanel(RightPanelType.REVIEW_AND_PAY, panelData);
    }, [openRightPanel, panelData]);

    const rendererFooter = () => {
      if (isHideFooter) return null;

      const payButtonTitle = onPackingCancel
        ? t("common.reviewAndPrepay")
        : t("common.reviewAndPay");

      return (
        <Footer>
          {onPackingCancel && (
            <CancelButton onClick={onPackingCancel} color={ButtonColor.Red}>
              <CancelIcon type={IconType.Close} />
              {t("common.cancel")}
            </CancelButton>
          )}
          {showPayButton && (
            <PayButton
              onClick={clickReviewAndPay}
              variant={ButtonVariant.Filled}
              color={ButtonColor.Secondary}
            >
              {payButtonTitle}
            </PayButton>
          )}
          {isPaid && (
            <PayButton
              onClick={clickAlreadyPayed}
              variant={ButtonVariant.Filled}
              color={ButtonColor.Secondary}
            >
              {`${t("parcels.paymentPaid")} ($${
                detailed?.estimate?.total
                  ? formatter.currency(detailed?.estimate?.total)
                  : ""
              })`}
            </PayButton>
          )}
        </Footer>
      );
    };

    const renderItemsList = (items: any, isExcessItems = false) => {
      const itemsCount = (!!items && items.length) || 0;
      const localizedTitle = isExcessItems
        ? t("parcels.excessPackagesIncluded")
        : t("parcels.packagesIncluded");
      const trackNumberColor = isExcessItems ? theme.red : theme.black;
      return itemsCount === 0 ? null : (
        <Section ref={contentRef}>
          <SectionHeader>
            <HeaderTitle>
              <Icon type={IconType.Package} />
              {`${localizedTitle} (${itemsCount}):`}
              {isExcessItems ? <ExcessMarker /> : null}
            </HeaderTitle>
          </SectionHeader>
          <InnerSection>
            <SectionContent>
              <PackagesList
                extended
                trackNumberColor={trackNumberColor}
                items={items}
                foundItemId={foundPackageId}
                handleClick={clickPackagesItem}
                serviceRequestPanelData={{
                  detailedItemID: id,
                  consolidationSku: detailed?.sku,
                  returnPreviousPanel: panelData.returnPreviousPanel,
                  getShipmentType: "OutgoingDetailedShipment",
                  readOnly: true,
                }}
              />
            </SectionContent>
          </InnerSection>
        </Section>
      );
    };

    const renderPackageInfo = (pkg: any, showMPSInfo = false) => {
      const pictures = pkg.pictures ?? [];
      const items = pkg.items;
      const sku = pkg.sku;

      const weight = pkg.weight;
      const height = pkg.height;
      const width = pkg.width;
      const depth = pkg.depth;

      const itemsUnitsTitle =
        items.length === 1 ? t("parcels.item") : t("parcels.items");
      const itemsTitle = `${items.length} ${itemsUnitsTitle}`;
      const weightTitle = `${formatter.weight(weight)} ${t(
        `units.${units}.primaryShort`,
      )}`;
      const itemsPerWeightTitle = `${itemsTitle} / ${weightTitle}`;

      const infoSection = (
        <>
          <SectionHeader>
            <SKUTitle>{sku}</SKUTitle>
          </SectionHeader>
          <SectionContent>{itemsPerWeightTitle}</SectionContent>
          <SectionContent>
            <PackageSizes
              width={width}
              height={height}
              depth={depth}
              units={units}
            />
          </SectionContent>
        </>
      );

      return (
        <>
          {(showMPSInfo || !!pictures.length) && (
            <InnerSection>
              {showMPSInfo ? infoSection : null}
              <SectionContent>
                {!!pictures.length && (
                  <ImageSection>
                    <ImageViewer2 images={pictures} />
                  </ImageSection>
                )}
              </SectionContent>
            </InnerSection>
          )}
          {renderItemsList(excessItems, true)}
          {renderItemsList(items)}
        </>
      );
    };

    return (
      services && (
        <>
          <ContentWrapper isHideFooter={isHideFooter}>
            <Section>
              <SectionHeader>
                <HeaderTitle>
                  <Icon type={IconType.Address} />{" "}
                  {t("parcels.shippingAddress")}:
                </HeaderTitle>
                <ChangeButton
                  color={ButtonColor.Primary}
                  hidden={disabledChange}
                  onClick={clickChangingAddress}
                >
                  {t("common.change")}
                </ChangeButton>
              </SectionHeader>
              <PanelAddressItem
                address={detailed as AddressDto}
                isHideButtons
              />
            </Section>
            <Section>
              <SectionHeader>
                <HeaderTitle>
                  <IconPng type="turbine" /> {t("parcels.shippingMethod")}:
                </HeaderTitle>
                <ChangeButton
                  color={ButtonColor.Primary}
                  hidden={disabledChangeForShippingMethod}
                  onClick={editDeliveryMethod}
                >
                  {t("common.change")}
                </ChangeButton>
              </SectionHeader>
              <InnerSection>
                <SectionContent>
                  <ServiceLogo serviceKey={delivery} />
                  {delivery}
                </SectionContent>
              </InnerSection>
            </Section>
            <Section>
              <SectionHeader>
                <HeaderTitle>
                  <IconPng type="tape" /> {t("parcels.packingGrade")}:
                </HeaderTitle>
                <ChangeButton
                  color={ButtonColor.Primary}
                  hidden={disabledChangeForPackingGrade}
                  onClick={editPackingGrade}
                >
                  {t("common.change")}
                </ChangeButton>
              </SectionHeader>
              <InnerSection>
                <SectionContent>
                  <ServiceLogo serviceKey={packingCode} />
                  {packingTitle}
                  {getAddonsIconsImage(
                    detailed?.consolidation_addons?.map((x: any) => x.code),
                    consolidationAddonsMethods,
                    18,
                    (x: any) => x.code,
                  )}
                </SectionContent>
              </InnerSection>
            </Section>
            {showPackedParcelInformation ? (
              <Section>
                <HeaderTitle>
                  <Icon type={IconType.Package} />
                  {hasFewPackages
                    ? t("parcels.parcelsInformation")
                    : t("parcels.parcelInformation")}
                  :
                </HeaderTitle>
                {hasFewPackages && (
                  <InnerSection>
                    <PackagesPagination
                      count={packages.length}
                      selectedIndex={selectedPackageIndex}
                      onSelect={handleSetSelectPackageIndex}
                    />
                  </InnerSection>
                )}
              </Section>
            ) : null}
            {showPackedParcelInformation &&
              renderPackageInfo(packages[selectedPackageIndex], hasFewPackages)}
            {showAllPackagesList && renderItemsList(excessItems, true)}
            {showAllPackagesList && renderItemsList(items)}
          </ContentWrapper>
          {rendererFooter()}
        </>
      )
    );
  },
);

export default ParcelInfoTab;
