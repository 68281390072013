export enum UserAuthQueryKey {
  UserAuthSignIn = "UserAuthSignIn",
  UserAuthSignUp = "UserAuthSignUp",
  UserAuthLogOut = "UserAuthLogOut",
  UserAuthGetProfile = "UserAuthGetProfile",
  UserAuthPasswordReset = "UserAuthPasswordReset",
  UserAuthChangePassword = "UserAuthChangePassword",
  UserUpdateProfile = "UserUpdateProfile",
}

export enum DashboardQueryKey {
  Dashboard = "Dashboard",
  FAQ = "FAQ",
  KBHelpers = "KBHelpers ",
}

export enum ServicesRequestsQueryKey {
  ServicesRequestsGetRequestTypes = "ServicesRequestsGetRequestTypes",
  ServicesRequestCreateRequestTransferShipment = "ServicesRequestCreateRequestTransferShipment",
  ServicesRequestCompleteTransferShipment = "ServicesRequestCompleteTransferShipment",
}

export enum ServicesQueryKey {
  ServicesDeliveryMethods = "ServicesDeliveryMethods",
  ServicesAllDeliveryMethods = "ServicesAllDeliveryMethods",
  ServicesPackingMethods = "ServicesPackingMethods",
  ServicesAddonsMethods = "ServicesAddonsMethods",
  ServicesConsolidationAddonsMethods = "ServicesConsolidationAddonsMethods",
}

export enum ItemsQueryKey {
  ItemsTools = "ItemsTools",
}

export enum BooksQueryKey {
  BooksTransactionsHistory = "BooksTransactionsHistory",
  BooksCreateRechargeBalance = "BooksCreateRechargeBalance",
}

export enum HistoryQueryKey {
  History = "History",
  HistoryDetailed = "HistoryDetailed",
  HistoryFullDetailed = "HistoryFullDetailed",
  HistoryCustomsDeclarations = "HistoryCustomsDeclarations",
  HistoryDetailedShipment = "HistoryDetailedShipment",
}

export enum DestinationQueryKey {
  DestinationAddresses = "DestinationAddresses",
  DestinationCreateAddress = "DestinationCreateAddress",
  DestinationUpdateAddress = "DestinationUpdateAddress",
  DestinationDeleteAddress = "DestinationDeleteAddress",
}

export enum CartQueryKey {
  Cart = "Cart",
  CartDelete = "CartDelete",
  CartSetPartnerId = "CartSetPartnerId",
  CartSetAddress = "CartSetAddress",
  CartSetDeliveryMethod = "CartSetDeliveryMethod",
  CartSetPackingMethod = "CartSetPackingMethod",
  CartSetAddonMethod = "CartSetAddonMethod",
  CartDeleteAddonsMethod = "CartDeleteAddonsMethod",
  CartSetConsolidationAddonsMethod = "CartSetConsolidationAddonsMethod",
  CartConsolidate = "CartConsolidate",
  CartDescriptionsByHsCodes = "CartDescriptionsByHsCodes",
  CartImportCustomInformation = "CartImportCustomInformation",
  CartUpdateCustomInformation = "CartUpdateCustomInformation",
  CartDeleteCustomInformation = "CartDeleteCustomInformation",
  CartImportCustomDeclarations = "CartImportCustomDeclarations",
}

export enum PurchaseQueryKey {
  PurchaseCheckout = "PurchaseCheckout",
  PurchaseCheckoutPayPal = "PurchaseCheckoutPayPal",
  PurchasePaymentCompleteViaPayPal = "PurchasePaymentCompleteViaPayPal",
  PurchaseCheckoutCreditCard = "PurchaseCheckoutCreditCard",
  PurchasePaymentCompleteAllViaPayPal = "PurchasePaymentCompleteAllViaPayPal",
  PurchaseCheckoutPayPalAll = "PurchaseCheckoutPayPalAll",
  PurchasePaymentConfirmAll = "PurchasePaymentConfirmAll]",
}

export enum OutgoingQueryKey {
  Outgoing = "Outgoing",
  OutgoingUpdateComment = "OutgoingUpdateComment",
  OutgoingDetailedItem = "OutgoingDetailedItem",
  OutgoingDetailedShipment = "OutgoingDetailedShipment",
  OutgoingCustomsDeclarations = "OutgoingCustomsDeclarations",
  OutgoingImportCustomInformation = "OutgoingImportCustomInformation",
  OutgoingUpdateCustomInformation = "OutgoingUpdateCustomInformation",
  OutgoingImportCustomDeclarations = "OutgoingImportCustomDeclarations",
  OutgoingUpdateDeliveryMethods = "OutgoingUpdateDeliveryMethods",
  OutgoingUpdateAddress = "OutgoingUpdateAddress",
  OutgoingCancelConsolidation = "OutgoingCancelConsolidation",
  OutgoingUpdateRequireInsurance = "OutgoingUpdateRequireInsurance",
  OutgoingDeliveryMethods = "OutgoingDeliveryMethods",
  OutgoingUpdateConsolidationAddons = "OutgoingUpdateConsolidationAddons",
  OutgoingRemoveItemFromConsolidation = "OutgoingRemoveItemFromConsolidation",
}

export enum StorageQueryKey {
  Storage = "Storage",
  StorageTransferring = "StorageTransferring",
  StorageInventoryItems = "StorageInventoryItems",
  StorageShops = "StorageShops",
  StorageShipment = "StorageShipment",
  StorageCreateCustomDeclaration = "StorageCreateCustomDeclaration",
  StorageUpdateCustomDeclaration = "StorageUpdateCustomDeclaration",
  StorageDeleteCustomDeclaration = "StorageDeleteCustomDeclaration",
  StorageImportCustomDeclarations = "StorageImportCustomDeclarations",
  StorageUpdateAddons = "StorageUpdateAddons",
  StorageUpdateComment = "StorageUpdateComment",
  StorageUpdateItemComment = "StorageUpdateItemComment",
}

export enum ExpectedQueryKey {
  ExpectedItems = "ExpectedItems",
  ExpectedCreateExpectedItem = "ExpectedCreateExpectedItem",
  ExpectedDeleteExpectedItem = "ExpectedDeleteExpectedItem",
  ExpectedUpdateExpectedItem = "ExpectedUpdateExpectedItem",
  ExpectedCustomsDeclarations = "ExpectedCustomsDeclarations",
  ExpectedCreateCustomInformation = "ExpectedCreateCustomInformation",
  ExpectedDeleteCustomInformation = "ExpectedDeleteCustomInformation",
  ExpectedUpdateCustomInformation = "ExpectedUpdateCustomInformation",
  ExpectedShops = "ExpectedShops",
}
