import { useEffect } from "react";

import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";

import ServicesRequestAPI from "@/api/servicesRequest_api";

import { useServicesRequestStore } from "@/store";
import { ServiceRequestsResponseDto } from "@/types/api/service_requests";
import { ServicesRequestsQueryKey as QueryKey } from "@/types";

export function useRequestTypes(
  options?: Omit<
    UseQueryOptions<ServiceRequestsResponseDto[]>,
    "queryKey" | "queryFn"
  >,
) {
  const { updateServiceRequestTypes } = useServicesRequestStore();
  const fetchRequestTypes = useQuery({
    queryKey: [QueryKey.ServicesRequestsGetRequestTypes],
    queryFn: ServicesRequestAPI.getRequestTypes,
    ...options,
  });

  const { data: requestTypesData, isSuccess: isSuccessRequestTypes } =
    fetchRequestTypes;

  useEffect(() => {
    if (isSuccessRequestTypes && requestTypesData) {
      updateServiceRequestTypes(requestTypesData);
    }
  }, [isSuccessRequestTypes, requestTypesData]);

  return fetchRequestTypes;
}

export const useCreateRequestTransferShipment = () => {
  const queryClient = useQueryClient();
  const { transferRequests, updateTransferRequests } =
    useServicesRequestStore();

  return useMutation({
    mutationFn: ServicesRequestAPI.createRequestTransferShipment,
    onSuccess: (resData, { id }) => {
      const data = resData.data;
      const transferCode = data.code;

      updateTransferRequests({
        ...transferRequests,
        [id]: { id, transferCode, status: "requested" },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.ServicesRequestCreateRequestTransferShipment],
      });
    },
  });
};

export const useCompleteRequestTransferShipment = () => {
  const queryClient = useQueryClient();
  const { transferRequests, updateTransferRequests } =
    useServicesRequestStore();

  return useMutation({
    mutationFn: ServicesRequestAPI.completeTransferShipment,
    onSuccess: (resData, { id }) => {
      updateTransferRequests({
        ...transferRequests,
        [id]: { id, status: "completed" },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.ServicesRequestCompleteTransferShipment],
      });
    },
  });
};
