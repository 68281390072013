import React, { FC, useEffect, useState } from "react";

import { Container } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { LoadingBar } from "@/components/LoadingBar";

import { toastResponseError } from "@/utils/responseMessageHelper";
import {
  useHistory,
  useHistoryFullDetailed,
} from "@/hooks/react-query/history";
import { useAllDeliveryMethods } from "@/hooks/react-query/services";

import { RightPanelType } from "@/store/useRightPanelStore";
import {
  useAuthStore,
  useHistoryStore,
  useRightPanelStore,
  useServicesStore,
} from "@/store";

import { HistoryHeader } from "./components/HistoryHeader";
import { Week } from "./components/Week";
import { HistoryEmpty, Wrapper } from "./History.styles";

const History: FC = React.memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const [year, setYear] = useState<number | null>(null);
  const [consolidationId, setConsolidationId] = useState<number | null>(null);

  const {
    isSuccess: isSuccessHistory,
    isLoading: isHistoryLoading,
    error,
  } = useHistory(year!, { enabled: !!year });
  const { updateSelectedYear, selectedYear, historyItems } = useHistoryStore();

  const {
    userAuth: { display_weight_in: units },
  } = useAuthStore();
  const { openRightPanel } = useRightPanelStore();
  const { allDeliveryMethods } = useServicesStore();

  useHistoryFullDetailed(consolidationId!, {
    enabled: !!consolidationId,
  });

  const { refetch: getAllDeliveryMethods } = useAllDeliveryMethods({
    enabled: false,
  });

  useEffect(() => {
    !allDeliveryMethods.length && getAllDeliveryMethods();

    const receivedAtYear =
      location.state &&
      location.state.receivedAt &&
      location.state.receivedAt.slice(0, 4);
    const year = receivedAtYear || new Date().getFullYear();

    updateSelectedYear(year);
    setYear(year);

    if (location.state) {
      openRightPanel(RightPanelType.WEEK_ITEM, {
        id: location.state.consolidationId,
        foundPackageId: location.state.shipmentId,
        shippingMethod: location.state.shippingMethod,
        weekNumber: location.state.weekNumber,
        selectedYear: receivedAtYear ? receivedAtYear : selectedYear,
      });
      navigate(location.pathname, { state: undefined, replace: true });
    }
  }, []);

  useEffect(() => {
    if (
      isSuccessHistory &&
      location.state &&
      location.state.shipmentId &&
      location.state.consolidationId
    ) {
      setConsolidationId(location.state.consolidationId);
    }
  }, [isSuccessHistory]);

  useEffect(() => {
    if (error) {
      toastResponseError(error);
    }
  }, [error]);

  const hasItems = !!Object.keys(historyItems.selected_year).length;
  const weeks = hasItems ? Object.keys(historyItems.selected_year) : [];

  return (
    <Wrapper>
      {hasItems && (
        <Container fluid>
          <LoadingBar isLoading={isHistoryLoading} isCartLoading={false} />
          <HistoryHeader years={historyItems.archived} />
          {!isHistoryLoading &&
            weeks
              .reverse()
              .map((week) => (
                <Week
                  units={units}
                  key={week}
                  numberWeek={week}
                  selectedYear={selectedYear ?? new Date().getFullYear()}
                  items={historyItems.selected_year[week]}
                />
              ))}
        </Container>
      )}
      {!weeks.length && (
        <HistoryEmpty>{t("parcels.emptyHistory")}</HistoryEmpty>
      )}
    </Wrapper>
  );
});

export default History;
