import React from "react";

import { getAddonsIconsImage } from "@/containers/Delivery/helpers";
import { ServiceTooltips } from "@/containers/Storage/components/ServiceTooltip";
import { Icon } from "@/components/Icon";
import { Logo } from "@/components/Logo";

import { theme } from "@/styles";

import { ServiceRequestPanelData } from "@/store/useRightPanelStore";
import { useServicesStore } from "@/store";
import { IconType } from "@/enums";
import { CartItem } from "@/types/api/cart";
import { Item } from "@/types/api/history";

import {
  Comment,
  DetailedButton,
  ItemContent,
  ItemName,
  ListItem,
  ListWrapper,
  OpenItemWrapper,
  RightArrowIcon,
  ServiceRequests,
  TrackingNumber,
} from "./PackagesList.styles";

interface PackagesListProps<T> {
  items?: T[];
  handleClick: (item: T) => void;
  className?: string;
  extended?: boolean;
  foundItemId: number;
  serviceRequestPanelData?: ServiceRequestPanelData;
  trackNumberColor?: string;
}

const PackagesList = React.memo(
  <T extends Item | CartItem>({
    items = [],
    extended = false,
    trackNumberColor = theme.black,
    className = "",
    foundItemId,
    handleClick,
    serviceRequestPanelData,
  }: PackagesListProps<T>) => {
    const { getSelectAddons } = useServicesStore();
    const addons = getSelectAddons();

    return (
      <ListWrapper className={className}>
        {items.map((item) => {
          const logo = item.emblem_thumb_url;
          const comment = item.customer_comment;
          const itemAddons = item.addons ?? [];
          const selected = foundItemId === item.shipment_id;

          const requests = item.service_requests_map ?? [];
          const hasServiceRequests = !!requests && !!requests.length;
          const id = item.id;

          // Display "tracking_number" if SKU starts with "S", and "description" if SKU starts with "I".
          let mainDescription;
          if (item.sku.startsWith("I")) {
            mainDescription = item.description;
          } else {
            mainDescription = item.shipment_tracking_number;
          }

          const ListItemChild = (
            <ListItem
              key={id}
              $extended={extended}
              selected={selected}
              onClick={() => extended && handleClick(item)}
            >
              <ItemContent>
                <ItemName>
                  {logo ? (
                    <Logo image={logo} />
                  ) : (
                    <Icon type={IconType.Package} />
                  )}
                  <TrackingNumber $trackNumberColor={trackNumberColor}>
                    {mainDescription}
                  </TrackingNumber>
                  {extended && getAddonsIconsImage(itemAddons, addons)}
                  {hasServiceRequests && (
                    <ServiceRequests>
                      <ServiceTooltips
                        parcel={{ ...item, id: item.shipment_id }}
                        requests={requests}
                        zIndex={3}
                        additionalPanelData={serviceRequestPanelData}
                      />
                    </ServiceRequests>
                  )}
                </ItemName>
                {comment && <Comment>{comment}</Comment>}
              </ItemContent>
              {extended && (
                <OpenItemWrapper onClick={() => handleClick(item)}>
                  <RightArrowIcon type={IconType.RightArrow} />
                </OpenItemWrapper>
              )}
            </ListItem>
          );

          if (!extended) return ListItemChild;
          return <DetailedButton key={id}>{ListItemChild}</DetailedButton>;
        })}
      </ListWrapper>
    );
  },
);

export default PackagesList;
