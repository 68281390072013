import React, { FC, useCallback, useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Tab, TabsView } from "@/containers/RightPanel/components";
import {
  BackButton,
  Content,
  ContentWrapper,
  Heading,
  LeftArrowIcon,
} from "@/containers/RightPanel/RightPanel.styles";
import CustomsDeclarationV2 from "@/components/CustomDeclaration/CustomsDeclarationV2";
import ParcelIcon from "@/components/Icon/icons/parcel.svg";
import { ImageViewer2 } from "@/components/ImageViewer2";

import { getFormattedDate } from "@/utils/formatDate";
import { toastResponseError } from "@/utils/responseMessageHelper";
import { useHistoryDetailedShipment } from "@/hooks/react-query/history";
import { useOutgoingDetailedShipment } from "@/hooks/react-query/outgoing";
import { useRequestTypes } from "@/hooks/react-query/servicesRequests";
import {
  useShipment,
  useStorageCreateCustomsDeclaration,
  useStorageDeleteCustomsDeclaration,
  useStorageUpdateCustomsDeclaration,
} from "@/hooks/react-query/storage";

import useOutgoingStore from "@/store/useOutgoingStore";
import {
  PanelDataByType,
  RightPanelType,
  StorageItemPanelData,
} from "@/store/useRightPanelStore";
import {
  useAuthStore,
  useHistoryStore,
  useRightPanelStore,
  useServicesRequestStore,
  useStorageStore,
} from "@/store";
import { ButtonColor, IconType } from "@/enums";
import { getDayjsLocale } from "@/translations/dayjs-locale";

import {
  InventoryItemComment,
  InventoryItemCommentSkeleton,
} from "../InventoryItemComment";
import { ServicesButtons } from "../ServiceButtons";
import { ShipmentComment } from "../ShipmentComment";
import {
  Container,
  ContentContainer,
  ImageSection,
  ParcelInfoContainer,
  ParcelInfoKey,
  ParcelInfoValue,
  ShipmentLogo,
  Spinner,
  SpinnerSection,
  Title,
} from "./StorageItemPanel.styles";

interface StorageItemPanelProps {
  isOpen: boolean;
  panelData: StorageItemPanelData;
}

const StorageItemPanel: FC<StorageItemPanelProps> = React.memo(
  ({ isOpen, panelData }) => {
    const { i18n, t } = useTranslation("common");

    const { types } = useServicesRequestStore();
    const { openRightPanel } = useRightPanelStore();

    const id = panelData.id;
    useShipment(id);

    const getShipmentType = panelData.getShipmentType;

    const { refetch: getShipmentHistory, error: errorGetShipmentHistory } =
      useHistoryDetailedShipment(id!, {
        enabled: false,
      });

    const { refetch: getShipmentOutgoing, error: errorGetShipmentOutgoing } =
      useOutgoingDetailedShipment(id!, {
        enabled: false,
      });

    const { mutateAsync: createCustomsDeclaration } =
      useStorageCreateCustomsDeclaration();
    const { mutateAsync: updateCustomsDeclaration } =
      useStorageUpdateCustomsDeclaration();
    const { mutateAsync: deleteCustomsDeclaration } =
      useStorageDeleteCustomsDeclaration();

    const { historyDetailedShipment, isLoadingHistoryDetailedShipment } =
      useHistoryStore();
    const { detailedShipment, isLoadingShipment: isLoadingProp } =
      useStorageStore();

    const {
      isLoadingDetailedShipment: isLoadingOutgoingShipment,
      detailedShipment: outgoingDetailedShipment,
    } = useOutgoingStore();

    const { refetch: getRequestTypes } = useRequestTypes({
      enabled: false,
    });

    const getShipment =
      getShipmentType === "HistoryDetailedShipment"
        ? getShipmentHistory
        : getShipmentOutgoing;
    const locale = getDayjsLocale(i18n.language);
    const navigate = useNavigate();
    const shipment =
      detailedShipment[id] ||
      outgoingDetailedShipment[id] ||
      (historyDetailedShipment?.[id] ?? {});

    const {
      userAuth: { display_weight_in: units },
    } = useAuthStore();

    const declarations = useMemo(
      () => shipment.items?.[0]?.customs_declarations,
      [shipment.items?.[0]],
    );

    const readOnly = !!panelData.readOnly;
    const overrideAndEnablePackOptions = panelData.overrideAndEnablePackOptions;
    const isCartVariant = panelData.isCartVariant;
    const updateComment = panelData.updateComment;
    const updateItemComment = panelData.updateItemComment;
    const showShipment = !!Object.keys(shipment).length;
    const isLoading =
      isLoadingProp ||
      isLoadingOutgoingShipment ||
      isLoadingHistoryDetailedShipment;

    const receivedAt = shipment?.received_at ?? new Date();
    const received = showShipment ? getFormattedDate(receivedAt, locale) : "";

    const hasInventoryItems = panelData.hasInventoryItems;
    const preventImageZoom = panelData.preventImageZoom;

    // originalItem can either be parcel if coming from StorageList and in that case it doesn't have SKU prop loaded
    // if it has SKU loaded then it is inventory item if SKU starts with letter I
    const originalItem = panelData.originalItem ?? {};
    const originalItemId = originalItem.id;
    const originalItemLatest =
      shipment && shipment.items?.find((item) => item.id === originalItemId);

    const isInventoryItem =
      originalItem.sku && originalItem.sku.startsWith("I");
    let itemDetails = isInventoryItem ? originalItemLatest : shipment;

    useEffect(() => {
      if (!showShipment && id) getShipment();
    }, [isOpen, getShipment, id, showShipment]);

    useEffect(() => {
      if (showShipment && !panelData.preventReLoading) getShipment();

      if (!types.length) {
        getRequestTypes().catch(toastResponseError);
      }
    }, [id, getRequestTypes, types, getShipment, showShipment, panelData]);

    useEffect(() => {
      const errorMessage = errorGetShipmentHistory || errorGetShipmentOutgoing;
      if (errorMessage) toastResponseError(errorMessage);
    }, [errorGetShipmentHistory, errorGetShipmentOutgoing]);

    const openServicePanel = useCallback(
      (requestType: string, isInventoryItemVariant?: boolean) =>
        openRightPanel(RightPanelType.SERVICE_REQUEST, {
          ...panelData,
          requests: shipment.service_requests,
          requestType,
          preventReLoading: true,
          requestInventoryShipmentData: shipment,
          readOnly,
          isInventoryItemVariant,
        }),
      [openRightPanel, panelData, shipment, readOnly],
    );

    const openPackOptions = useCallback(
      () =>
        openRightPanel(RightPanelType.PACK_OPTIONS, {
          ...panelData,
          preventReLoading: true,
          returnScreen: RightPanelType.STORAGE_ITEM,
          readOnly,
          overrideAndEnablePackOptions,
        }),
      [openRightPanel, panelData, readOnly, overrideAndEnablePackOptions],
    );

    const openInventoryPackOptions = useCallback(
      () =>
        openRightPanel(RightPanelType.PACK_OPTIONS, {
          ...panelData,
          preventReLoading: true,
          returnScreen: RightPanelType.STORAGE_ITEM,
          readOnly,
          useInventoryAddons: true,
          overrideAndEnablePackOptions,
        }),
      [openRightPanel, panelData, readOnly, overrideAndEnablePackOptions],
    );

    const openInventory = useCallback(
      () => navigate(`/parcels/inventory/${originalItem.id}`),
      [originalItem, navigate],
    );

    const handleBack = useCallback(() => {
      if (panelData.returnPreviousPanel) {
        openRightPanel(panelData.returnPreviousPanel, {
          ...panelData,
          id: panelData.consolidationId,
          returnPreviousPanel: panelData.rootReturnPanel,
          sku: panelData.consolidationSku,
        } as PanelDataByType<typeof panelData.returnPreviousPanel>);
      }
    }, [openRightPanel, panelData]);

    const mainTitle = isInventoryItem
      ? itemDetails?.description
      : panelData.description;
    const pictures = itemDetails?.pictures ?? [];
    const weight = itemDetails?.weight || "";

    return (
      <ContentWrapper key={id}>
        <Heading>
          {readOnly && (
            <BackButton color={ButtonColor.Black50} onClick={handleBack}>
              <LeftArrowIcon type={IconType.Arrow} />
              {panelData.consolidationSku}:
            </BackButton>
          )}
          <ShipmentLogo
            $readOnly={readOnly}
            image={panelData.emblem_thumb_url || ParcelIcon}
          />
          <Title $readOnly={readOnly}>{mainTitle}</Title>
        </Heading>
        <TabsView>
          <Tab title={t("parcels.details")}>
            <ContentContainer>
              <Container>
                {!pictures?.length && isLoading && (
                  <SpinnerSection>
                    <Spinner isActive={isLoading} />
                  </SpinnerSection>
                )}
                {!!pictures?.length && (
                  <ImageSection>
                    <ImageViewer2
                      images={pictures}
                      preventImageZoom={preventImageZoom}
                    />
                  </ImageSection>
                )}
                <ParcelInfoContainer>
                  <div>
                    <ParcelInfoKey>{t("parcels.receivedDate")}: </ParcelInfoKey>
                    <ParcelInfoValue>{received}</ParcelInfoValue>
                  </div>
                  <div>
                    <ParcelInfoKey>{`${t("parcels.weight")}: `}</ParcelInfoKey>
                    <ParcelInfoValue>{`${weight} ${t(
                      `units.${units}.primaryShort`,
                    )}`}</ParcelInfoValue>
                  </div>
                </ParcelInfoContainer>
                {isInventoryItem && (
                  <>
                    <ParcelInfoContainer>
                      <div>
                        <ParcelInfoKey>{t("parcels.package")}: </ParcelInfoKey>
                        <ParcelInfoValue>
                          {shipment.description}
                        </ParcelInfoValue>
                      </div>
                    </ParcelInfoContainer>
                    {!originalItemLatest && <InventoryItemCommentSkeleton />}
                    {originalItemLatest && (
                      <InventoryItemComment
                        shipment={originalItemLatest}
                        updateComment={updateItemComment}
                      />
                    )}
                    <ServicesButtons
                      shipment={shipment}
                      openPackOptions={openInventoryPackOptions}
                      openServicePanel={openServicePanel}
                      isCartVariant={!!isCartVariant}
                      isInventoryItemVariant
                      isItemInCart={panelData.isItemInCart}
                      originalItem={originalItem}
                    />
                  </>
                )}
                {!isInventoryItem && (
                  <>
                    {!showShipment && <InventoryItemCommentSkeleton />}
                    {showShipment && (
                      <ShipmentComment
                        shipment={shipment}
                        updateComment={updateComment}
                      />
                    )}
                    <ServicesButtons
                      shipment={shipment}
                      openPackOptions={openPackOptions}
                      openServicePanel={openServicePanel}
                      openInventory={openInventory}
                      isCartVariant={!!isCartVariant}
                      hasInventoryItems={hasInventoryItems}
                      originalItem={originalItem}
                    />
                  </>
                )}
              </Container>
            </ContentContainer>
          </Tab>
          <Tab title={t("parcels.customsData")}>
            <Content>
              {isOpen && showShipment && (
                <CustomsDeclarationV2
                  itemId={id}
                  readOnly={readOnly}
                  declarations={declarations}
                  create={createCustomsDeclaration}
                  update={updateCustomsDeclaration}
                  remove={deleteCustomsDeclaration}
                  noPadding
                  isShipmentImportCustomsDeclaration
                  isShowHSCodeColumn
                />
              )}
            </Content>
          </Tab>
        </TabsView>
      </ContentWrapper>
    );
  },
);

export default StorageItemPanel;
