import { AxiosResponse } from "axios";
import {
  CustomsDeclaration as CustomDeclarationResponseDto,
  CustomsDeclaration,
  ImportCustomsDeclarationDto,
} from "types/api/common";
import {
  ShipmentResponseDto,
  ShipmentsUpdateCommentDto,
  ShopsResponseDto,
  StorageParamsDto,
  StorageResponseDto,
  StorageTransferringParamsDto,
  UpdateAddonsDto,
  UpdateShipmentsCustomDeclarationDto,
} from "types/api/shipments";

import axiosInstance from "./axios_instance";

const StorageAPI = {
  getStorage: ({
    page,
    filter,
  }: StorageParamsDto): Promise<AxiosResponse<StorageResponseDto[]>> =>
    axiosInstance.get("/shipments", {
      params: { page, ...filter },
    }),
  getShipment: async (id: number): Promise<ShipmentResponseDto> => {
    const res = await axiosInstance.get(`/shipments/${id}`);

    return res.data;
  },
  updateComment: async ({
    id,
    data,
  }: ShipmentsUpdateCommentDto): Promise<ShipmentResponseDto> => {
    const res = await axiosInstance.patch(`/shipments/${id}`, data);

    return res.data;
  },
  createCustomsDeclaration: ({
    itemId,
    data,
  }: {
    itemId: number;
    data: CustomsDeclaration;
  }): Promise<AxiosResponse<CustomDeclarationResponseDto>> =>
    axiosInstance.post(`/shipments/${itemId}/customs_declarations`, data),
  deleteCustomsDeclaration: ({
    id,
    itemId,
  }: {
    id: number;
    itemId: number;
  }): Promise<AxiosResponse<CustomDeclarationResponseDto[]>> =>
    axiosInstance.delete(`/shipments/${itemId}/customs_declarations/${id}`),
  updateCustomsDeclaration: ({
    id,
    itemId,
    data,
  }: UpdateShipmentsCustomDeclarationDto): Promise<
    AxiosResponse<CustomDeclarationResponseDto>
  > => {
    return axiosInstance.patch(
      `/shipments/${itemId}/customs_declarations/${id}`,
      data,
    );
  },

  getShops: async (): Promise<ShopsResponseDto[]> => {
    const res = await axiosInstance.get("/shop_emblems");

    return res.data;
  },
  getExpectedShops: async (): Promise<ShopsResponseDto[]> => {
    const res = await axiosInstance.get("/expected_shipments/shop_emblems");

    return res.data;
  },
  updateAddons: async ({
    id,
    data,
  }: UpdateAddonsDto): Promise<ShipmentResponseDto> => {
    const res = await axiosInstance.patch(
      `/shipments/${id}/update_addons`,
      data,
    );

    return res.data;
  },
  importCustomsDeclaration: async ({
    id,
    file,
  }: ImportCustomsDeclarationDto): Promise<CustomDeclarationResponseDto[]> => {
    const formData = new FormData();
    formData.append("customs_declaration_import[file]", file);

    const res = await axiosInstance.post(
      `/shipments/${id}/customs_declarations/import_declaration`,
      formData,
    );

    return res.data;
  },
  getStorageTransferring: ({
    page,
  }: StorageTransferringParamsDto): Promise<
    AxiosResponse<StorageResponseDto[]>
  > =>
    axiosInstance.get("/shipments/transferring", {
      params: { page },
    }),
};

export default StorageAPI;
