import React from "react";

import { Link as RouterLink } from "react-router-dom";
import styled, { css, DefaultTheme } from "styled-components";

import { Button } from "@/components/Button";
import { Link } from "@/components/Link";

export const Wrapper = styled.div`
  padding: 15px;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.lightgrey03};
`;

export const Content = styled.div`
  min-height: 85%;
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const HeadingLink = styled(RouterLink)`
  text-decoration: none;
  color: #000;
  font-weight: bold;
  font-size: 25.4px;
`;

export const LinksWrapper = styled.div`
  margin: 10px;
  width: 100%;
  max-width: 360px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const AuthLink = styled(Link)`
  margin: 5px;
  color: ${(props) => props.theme.darkGrey};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  width: 100%;
  height: 100%;
  padding: 25px 20px;
  background-color: #fff;
  border: ${({ theme }) => `1px solid ${theme.grey}`};
`;

export const FieldWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  ${(props) => props.theme.xs`
    display: block;
  `}

  & > * {
    margin-bottom: 10px;
    max-width: ${({ children }) =>
      React.Children.count(children) === 2 ? "calc(50% - 5px)" : "auto"};

    ${(props) => props.theme.xs`
       max-width: 100%
    `}
  }
`;

export const Fields = styled.div`
  margin-bottom: 15px;
  width: 100%;
`;

export const TermsWrapper = styled(FieldWrapper)`
  margin-top: 15px;
  justify-content: center;
  align-items: center;
`;

export const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  & > * {
    margin: 5px;
  }
`;

interface LabelProps {
  invalid?: boolean;
}

export const Label = styled.label<LabelProps>`
  width: 100%;
  position: relative;
  display: block;
  font-size: 12px;
  color: ${({ theme, invalid }) => (invalid ? theme.red : theme.black50)};
`;

export const LabelText = styled.p`
  margin-top: 0;
  text-align: left;
  margin-bottom: 8px;
`;

interface StyleProps {
  theme: DefaultTheme;
  $invalid?: boolean;
  $flatStyle?: boolean;
}

const fieldBorder = ({ theme, $invalid, $flatStyle }: StyleProps): string => {
  if ($invalid) {
    return `1px solid ${theme.red}`;
  } else if ($flatStyle) {
    return "none";
  } else {
    return `1px solid ${theme.grey}`;
  }
};

const fiedBoxShadow = ({ theme, $flatStyle }: StyleProps): string => {
  return $flatStyle ? "none" : `inset 0 1px 3px 0 ${theme.lightgrey}`;
};

export interface FieldProps {
  $withIcon?: boolean;
  $invalid?: boolean;
  disabled?: boolean;
  $flatStyle?: boolean;
  $height?: number;
}

export const Field = css<FieldProps>`
  outline: none;
  border: ${fieldBorder};
  box-shadow: ${fiedBoxShadow};
  padding: ${({ $withIcon }) =>
    $withIcon ? "11px 30px 11px 10px" : "11px 10px"};
  width: 100%;
  height: ${(props) => `${props.$height || 39}px`};
  font-size: 14px;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.black05 : "#fff !important"};
  transition: border-color 0.2s;
  text-align: start;

  &:focus {
    border-color: ${({ theme, $invalid, disabled }) =>
      !disabled && ($invalid ? theme.red : theme.primary)};
  }
`;

export const USD = styled.span`
  color: ${({ theme }) => theme.green};
  padding: 5px;

  ::before {
    content: "USD";
  }
`;

export const ToggleShowPasswordButton = styled(Button)`
  width: 38px;
  padding: 9px;
`;
