import { useState } from "react";

import {
  InfiniteData,
  QueryObserverResult,
  RefetchOptions,
} from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useFormik } from "formik";

import StorageAPI from "@/api/storage_api";

import formatter from "@/utils/formatter";

import { StorageItem, transformStorageItems } from "@/store/useStorageStore";
import { useStorageStore } from "@/store";
import { InventoryItemResponseDto } from "@/types/api/items";
import { StorageParamsDto, StorageResponseDto } from "@/types/api/shipments";
import { StorageSearchValues } from "@/types/StorageForms/Search";

const convertingToUTCDate = (date: Date) =>
  date &&
  new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();

const getDateFilter = (value: string | { startDate: Date; endDate: Date }) => {
  switch (value) {
    case "today":
      return { "q[gt_days_period]": 1 };
    case "yesterday":
      return { "q[gt_days_period]": 2, "q[lt_days_period]": 1 };
    case "lastWeek":
      return { "q[gt_days_period]": 7 };
    case "lastMonth":
      return { "q[gt_months_period]": 1 };
    default:
      return typeof value === "object"
        ? {
            "q[date_received_at_gteq]": convertingToUTCDate(value.startDate),
            "q[date_received_at_lteq]": convertingToUTCDate(value.endDate),
          }
        : {};
  }
};

// 25kg = 55lbs
export const getMaxWeightValue = (units: string) => (units === "kg" ? 25 : 55);

export const storageFormikSearchValues = {
  weightRange: [],
  date: "",
  vendor: null,
  location: "",
  trackingNumber: "",
};

export type StorageSearchFormikProps = {
  setStorageParamsDto: (values: StorageParamsDto) => void;
  setInventoryParamsDto: (values: StorageParamsDto) => void;
};

export const useStorageSearchFormik = ({
  setStorageParamsDto,
  setInventoryParamsDto,
}: StorageSearchFormikProps) => {
  const [isStorageList, setIsStorageList] = useState(true);

  const { storageSearchValues } = useStorageStore();

  const handleGetStorageSubmit = (values: StorageParamsDto) => {
    setIsStorageList(true);
    setStorageParamsDto(values);
  };

  const handleGetInventoryItemsSubmit = (values: StorageParamsDto) => {
    setIsStorageList(false);
    setInventoryParamsDto(values);
  };

  const formOptions = useFormik<StorageSearchValues>({
    initialValues: storageSearchValues,
    onSubmit: async (values: StorageSearchValues) => {
      const { weightRange, date, vendor, location, trackingNumber } = values;

      const dateFilter = getDateFilter(date);
      if (
        trackingNumber &&
        (trackingNumber.startsWith(">") || trackingNumber.startsWith(">>"))
      ) {
        const isContains = !trackingNumber.startsWith(">>");
        const itemsSearchTerm = trackingNumber.slice(isContains ? 1 : 2);

        return handleGetInventoryItemsSubmit({
          page: 1,
          filter: {
            ...(isContains && {
              description_or_customer_comment_cont: itemsSearchTerm,
            }),
            ...(!isContains && {
              description_or_customer_comment_eq: itemsSearchTerm,
            }),
          },
        });
      } else {
        return handleGetStorageSubmit({
          page: 1,
          filter: {
            "q[descriptions_or_customer_comment_cont]": trackingNumber || null,
            "q[weight_gteq]": weightRange && weightRange[0],
            "q[weight_lteq]": weightRange && weightRange[1],
            "q[vendor_eq]": vendor && vendor.value,
            "q[location_in]": location || null,
            ...dateFilter,
          },
        });
      }
    },
  });

  return { formOptions, isStorageList };
};

// updateIsNavigateFromInventory(true);
// updateIsLoadingStorage(true);
// let storageItems: StorageItem[] = [];
// for (let page = 1; page <= +totalStoragePage; page++) {
//   const paramsDto = {
//     page: page,
//     filter: {
//       "q[weight_gteq]": weightRange && weightRange[0],
//       "q[weight_lteq]": weightRange && weightRange[1],
//       "q[vendor_eq]": vendor && vendor.value,
//       "q[location_in]": location || null,
//       ...dateFilter,
//     },
//   };
//
//   const res = await StorageAPI.getStorage(paramsDto);
//
//   storageItems = [...storageItems, ...transformStorageItems(res.data)];
// }
//
// const storageItemsWithInventory = storageItems.filter(
//   (item) => item.items_quantity > 1,
// );
// const totalWeight = storageItemsWithInventory.reduce(
//   (sum, item) => sum + item.weight,
//   0,
// );
//
// updateStorageTotalAmount(storageItemsWithInventory.length);
// updateStorageTotalWeight(formatter.roundTo2Decimal(totalWeight));
// updateStorageItems(storageItemsWithInventory);
// updateIsLoadingStorage(false);
// updateStorageSearchValues(values as typeof storageFormikSearchValues);
//
// return;
