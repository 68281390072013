import { FC, memo, useCallback, useState } from "react";

import { Hidden, Visible } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  InventoryList,
  InventoryListPlaceholder,
} from "@/containers/Inventory/components";
import { Button } from "@/components/Button";
import { Checkbox } from "@/components/Checkbox";

import { StorageButton } from "@/styles/parcels";

import { useCart } from "@/hooks/react-query/cart";
import { useAddonsMethods } from "@/hooks/react-query/services";
import { useAddParcelsToCart, useShops } from "@/hooks/react-query/storage";
import { useProgressDelivery } from "@/hooks";

import {
  useAuthStore,
  useCartStore,
  useServicesStore,
  useStorageStore,
} from "@/store";
import { ButtonColor, ButtonSize, ButtonVariant } from "@/enums";

import { CartInfo } from "../CartInfo";
import { SelectedInfo } from "../SelectedInfo";
import {
  Buttons,
  ButtonsWrapper,
  SelectAllSwitchWrapper,
  StorageHeading,
  StyledGrid,
  StyledListHeader,
  StyledListHeaderTitle,
  Wrapper,
} from "./InventoryItems.styles";

const InventoryItems: FC = () => {
  const { isCartLoading, cart } = useCartStore();
  const {
    shops,
    isLoadingShipment: isLoading,
    inventoryItemsSearch: items,
    isLoadingInventoryItems,
  } = useStorageStore();
  const {
    userAuth: { display_weight_in: units },
    getAuthSelectFfid,
  } = useAuthStore();
  const { addonsMethods: addons } = useServicesStore();
  const { addParcelsToCart } = useAddParcelsToCart();

  useCart({ enabled: !isCartLoading });
  useAddonsMethods({ enabled: !addons.length });

  const userFfid = getAuthSelectFfid();
  const [, setAccess] = useProgressDelivery(userFfid);
  const { t } = useTranslation("common");
  const [selectedItems, setSelectedItems] = useState<any>({});
  const navigate = useNavigate();

  const hasItems = !!items.length;
  const cartItems = cart?.items ?? [];
  const isCartEmpty = !cartItems.length;
  const getIsItemInCart = (itemId: number) => {
    return cartItems.some((item) => item.id === itemId);
  };

  const selectedParcels = Object.values(selectedItems).filter(
    (item: any) => item.isSelectedState && !getIsItemInCart(item.id),
  );
  const selectableItems = items.filter(
    (item) =>
      !getIsItemInCart(item.id) &&
      ["Active", "InContainer", "ContainerPacked"].indexOf(item.status) !== -1,
  );
  const selectedParcelsWeight = selectedParcels
    .reduce((sum: number, item: any) => {
      const itemId = items.find((x) => x.id === item.id);
      return itemId?.weight ? sum + itemId.weight : sum;
    }, 0)
    .toFixed(2);
  const totalSelectableItemsWeight = selectableItems
    .reduce((sum: number, item: any) => sum + item.weight, 0)
    .toFixed(2);
  const hasSelected = selectedParcels.length > 0;
  const isAllSelected = selectedParcels.length === selectableItems.length;

  useShops({ enabled: !shops.length });

  const toggleAllItemsSelectState = (nextValue: any) => {
    const nextSelectedItems: any = {};
    selectableItems.forEach((item: any) => {
      nextSelectedItems[item.id] = selectedItems[item.id]
        ? {
            ...selectedItems[item.id],
            isSelectedState: nextValue,
          }
        : {
            id: item.id,
            isSelectedState: nextValue,
            ...(item.quantity > 1 && { selectedQuantity: 1 }),
          };
    });
    setSelectedItems(nextSelectedItems);
  };

  const handleSelectAll = () => {
    toggleAllItemsSelectState(true);
  };

  const handleDeselectAll = () => {
    toggleAllItemsSelectState(false);
  };

  const handleSelectItems = (items: any) => {
    setSelectedItems(items);
  };

  const handleSend = useCallback(() => {
    if (!hasSelected && !isCartEmpty) {
      navigate("/shipping");
      return;
    }

    const retVal = {
      id: selectedParcels.map((item: any) => item.id),
      decant_ids: selectedParcels
        .filter((x: any) => x.selectedQuantity)
        .reduce(
          (
            accumulator: {
              [key: string]: number;
            },
            item: any,
          ) => {
            return { ...accumulator, [item.id]: item.selectedQuantity };
          },
          {},
        ),
      useCartItemsApi: true,
      shipmentId: null,
    };
    addParcelsToCart(retVal);
    setSelectedItems({});
    if (isCartEmpty) {
      setAccess("initialSteps");
    }
  }, [selectedParcels, hasSelected, isCartEmpty, setAccess]);

  return (
    <Wrapper>
      <StyledGrid fluid>
        <StorageHeading>
          {!isLoading && !isLoadingInventoryItems && (
            <StyledListHeader>
              {selectableItems.length > 0 && (
                <Visible xs>
                  <SelectAllSwitchWrapper>
                    <Checkbox
                      checked={isAllSelected}
                      onChange={() =>
                        isAllSelected ? handleDeselectAll() : handleSelectAll()
                      }
                    />
                  </SelectAllSwitchWrapper>
                </Visible>
              )}
              <StyledListHeaderTitle>
                {t("parcels.total", { amount: selectableItems.length })}
                {selectableItems.length > 0 && (
                  <span>
                    {` / ${totalSelectableItemsWeight} ${t(
                      `units.${units}.primaryShort`,
                    )}`}
                  </span>
                )}
              </StyledListHeaderTitle>
              {selectableItems.length > 0 && (
                <Hidden xs>
                  {isAllSelected ? (
                    <StorageButton onClick={handleDeselectAll}>
                      {t("common.deselect")}
                    </StorageButton>
                  ) : (
                    <StorageButton onClick={handleSelectAll}>
                      {t("common.selectAll")}
                    </StorageButton>
                  )}
                </Hidden>
              )}
            </StyledListHeader>
          )}
        </StorageHeading>
        {hasItems && (
          <InventoryList
            units={units}
            items={items}
            selectedItems={selectedItems}
            disabled={isCartLoading}
            getIsItemInCart={getIsItemInCart}
            handleSelectItems={handleSelectItems}
            isInventoryItemsSearchVariant
          />
        )}
        {!hasItems && !isLoading && !isLoadingInventoryItems && (
          <InventoryListPlaceholder />
        )}
        {(hasSelected || !isCartEmpty) && (
          <ButtonsWrapper>
            <Buttons>
              {hasSelected ? (
                <SelectedInfo
                  weight={selectedParcelsWeight}
                  selectedParcelsNumber={selectedParcels.length}
                />
              ) : (
                <CartInfo />
              )}
              <Button
                variant={ButtonVariant.Filled}
                color={
                  hasSelected ? ButtonColor.Primary : ButtonColor.Secondary
                }
                size={ButtonSize.Large}
                disabled={isCartLoading}
                onClick={handleSend}
              >
                {hasSelected ? t("common.addToCart") : t("common.sendTheCart")}
              </Button>
              {hasSelected && (
                <StorageButton
                  disabled={isCartLoading}
                  onClick={handleDeselectAll}
                >
                  {t("common.deselect")}
                </StorageButton>
              )}
            </Buttons>
          </ButtonsWrapper>
        )}
      </StyledGrid>
    </Wrapper>
  );
};

export default memo(InventoryItems);
